import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientComponent } from './client/client.component';
import { ClientsComponent } from './clients/clients.component';
import { RoleGuard } from '@app/guards/roles.guard';
import { Role } from '@app-store/session/session.store';

const routes: Routes = [
	{
		path: '',
		component: ClientsComponent,
		canActivate: [RoleGuard],
		data: {
			roles: [Role.ADMIN, Role.PRODUCT_ADMIN],
		},
	},
	{
		path: 'new',
		component: ClientComponent,
		canActivate: [RoleGuard],
		data: {
			roles: [Role.ADMIN, Role.PRODUCT_ADMIN],
		},
	},
	{
		path: ':id',
		component: ClientComponent
	}
];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ClientRoutingModule { }
