import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PageControl } from '../model/page-control';
import { environment } from '@env/environment';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DefaultStatus } from '@app-model/login';
import { ClientPageableResponse, FileUploader, Plan, SimplifiedClientHelper } from '@app-model/client';
import { IdName } from '@app-model/application';
import { PromotedContent } from '@app-model/promotedContent';

@Injectable({
	providedIn: 'root'
})
export class ClientService {

	constructor(private http: HttpClient, private toastr: ToastrService) { }


	paginate(pageControl: PageControl): Observable<ClientPageableResponse> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField
		};

		if (pageControl.param && pageControl.param !== '' && pageControl.value && pageControl.value !== '') {
			body.param = pageControl.param;
			body.value = pageControl.value;
		}
		return this.http
			.post(`${environment.api}/client/list`, body)
			.pipe(map((response: ClientPageableResponse) => response));
	}

	getClientOptions(): Observable<IdName[]> {
		return this.http
			.get<IdName[]>(`${environment.api}/client/options`);
	}

	getSimplifiedClient(id: string): Observable<SimplifiedClientHelper> {
		return this.http.get<SimplifiedClientHelper>(`${environment.api}/client/simplified/${id}`);
	}


	getPromotedContents(clientId: string): Observable<PromotedContent[]> {
		return this.http.get<PromotedContent[]>(`${environment.api}/client/promotedContents/${clientId}`);
	}

	savePromotedContents(clientId: string, promotedContents: PromotedContent[]): Observable<PromotedContent[]> {
		return this.http.post<PromotedContent[]>(`${environment.api}/client/savePromotedContents/${clientId}`, promotedContents);
	}


	uploadBannerImage(file: File, clientId: string): Observable<FileUploader> {
		const body = new FormData();
		body.append('file', file);
		return this.http.post<FileUploader>(`${environment.api}/client/uploadPromotionBanner/${clientId}`, body);
	}

	uploadImageGridImage(file: File, clientId: string): Observable<FileUploader> {
		const body = new FormData();
		body.append('file', file);
		return this.http.post<FileUploader>(`${environment.api}/client/uploadPromotionGrid/${clientId}`, body);
	}

	uploadCarrouselImage(file: File, clientId: string, fileType: string): Observable<FileUploader> {
		const body = new FormData();
		body.append('file', file);
		return this.http.post<FileUploader>(`${environment.api}/client/uploadPromotionCarrousel/${clientId}/${fileType}`, body);
	}


	remove(id: string): Observable<DefaultStatus> {
		return this.http.post<DefaultStatus>(`${environment.api}/client/remove/${id}`, {});
	}


	syncConfig(clientId: string, configId: string): Observable<DefaultStatus> {
		return this.http.post<DefaultStatus>(`${environment.api}/client/runIntegrationSync/${clientId}/${configId}`, {});
	}

	getAvailablePlans(clientId?: string): Observable<Plan[]> {
		return this.http.get<Plan[]>(`${environment.api}/client/availablePlans/${clientId ? clientId : ''}`);
	}

	changePlan(clientId: string, planId: string): Observable<DefaultStatus> {
		return this.http.post<DefaultStatus>(`${environment.api}/client/changePlan/${clientId ? clientId + '/' : ''}${planId}`, {});
	}

	getCurrentPlan(clientId: string): Observable<Plan> {
		return this.http.get<Plan>(`${environment.api}/client/currentPlan/${clientId}`);
	}
}
