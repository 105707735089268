import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-star-rating',
	templateUrl: './star-rating.component.html',
	styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
	@Input()
	rating? = 0;

	@Input()
	maxRating? = 5;

	@Input()
	fontsize? = '24px';

	@Input()
	color? = '#ffe472';

	@Output()
	clickRating = new EventEmitter<number>();

	numbers = [];

	constructor() {}

	ngOnInit() {
		for (let a = 1; a <= this.maxRating; a++) {
			this.numbers.push(a);
		}
	}

	onRateProvider(rating: number) {
		this.rating = rating;
		this.clickRating.emit(rating);
	}
}
