
import { Observable, from } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import * as cacheBusting from '../i18n-cache-busting.json';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

export class TranslateServerLoader implements TranslateLoader {
    constructor(private http: HttpClient) {
    }

    public getTranslation(lang: string): Observable<any> {
        let path = `/assets/i18n/${lang}.json?v=${cacheBusting[lang]}`;
        if (environment.production) {
            path = environment.url + path;
        }
        return this.http.get(path);

    }
}

export function translateServerLoaderFactory(http: HttpClient): TranslateLoader {
    return new TranslateServerLoader(http);
}