import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables, LayoutPosition } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);
@Component({
  selector: 'app-doughnut-pie-chart',
  templateUrl: './doughnut-pie-chart.component.html',
  styleUrls: ['./doughnut-pie-chart.component.scss']
})
export class DoughnutPieChartComponent implements OnInit {

  constructor(
    public translate: TranslateService
  ) { }

  @Input() idChart: string
  @Input() labels: any
  @Input() data: any
  @Input() typeChart: any = 'doughnut'
  @Input() positionChart: LayoutPosition = "bottom"


  ngOnInit(): void {
    if(this.data !== undefined && this.labels !== undefined ) {
      this.handleInitChartjs()
    }
  }

  private handleInitChartjs(): void {

    const customColors = [
      '#054659', '#F18700', '#2A77AD', '#F9B000', '#3BB93B', '#7460DD', '#0099C9',
      '#F0BE4B', '#61CE61', '#20829E', '#9487D3', '#FFAC41', '#50BEDF', '#B9DFB9',
      '#6952D9', '#0DA9D9', '#F29E2F', '#1CAE94', '#B47F03', '#3494D9', '#503FA6',
      '#73D973', '#D97B00', '#EBA70A', '#EBC70A'
    ];


    const somaTotal = this.data.reduce((total, valor) => total + valor, 0);


    const porcentagens = this.data.map(valor => (valor / somaTotal) * 100);




    new Chart("ChartDoughnutComponent", {
      type: this.typeChart,
      data: {
        labels: [...this.labels],
        datasets: [{
          label: '',
          data: [...this.data],
          backgroundColor: customColors,
          borderColor: customColors,
          borderWidth: 0
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '50%',
        plugins: {
          tooltip: {
            callbacks: {
              title: (tooltipItem, data) => {
                return tooltipItem[0].label
              },
              label: (tooltipItem, data) => {
                const total = tooltipItem.dataset.data
                  .reduce((accumulator: number, currentValue: number) => accumulator + currentValue);
                return ' ' + `${(Math.round(tooltipItem.parsed) / total * 100).toFixed(0)}%` + " | " + total + ' ' + this.translate.instant('clicks');
              },
              labelTextColor: (tooltipItem, data) => {
                return "#FFFFFF"
              }
            },
            bodyFont: {
              size: 12,
              weight: 'bold'
            },
            position: 'nearest',
            titleColor: '#FFFFFF',
            backgroundColor: '#262626',
            displayColors: true
          },
          legend: {
            display: false,
            position: this.positionChart,

            labels: {
              padding: 20,
              font: {
                size: 14,
                weight: '400',
                lineHeight: 22,
                family: 'Roboto'
              },
              usePointStyle: true,
              boxHeight: 7,
              pointStyleWidth: 10,
            }

          },
          datalabels: {
            color: '#ffffff',
            font: {
              weight: '500',
              size: 16,
              lineHeight: 22,
            },
          },

          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const value = dataset.data[tooltipItem.index];
                const label = data.labels[tooltipItem.index];
                const porcentagem = porcentagens[tooltipItem.index].toFixed(2);
                return `${porcentagem}% | ${label}: ${value} cliques`;
              }
            }
          }
        }
      }
    });

  }

}
