<div class="buttons-list-enterprise" [cdkDropListData]="displayButtons" (cdkDropListDropped)="drop($event)" cdkDropList>
  <div class="table-container">
    <table class="table table-striped table-no-border">
      <thead>
        <tr class="tr-content">
          <th class="table-col-type th-title-style">Tipo</th>
          <th class="table-col-main th-title-style">Principal</th>
          <th class="table-col-active th-title-style">Ativar</th>
        </tr>
      </thead>
      <tbody>
        <tr class="th-content" *ngFor="let button of displayButtons; let i = index" cdkDrag [cdkDragData]="button">
          <td class="box-buttons-options-enterprise"
            [ngClass]="{'selected-row': button.selectedButton === 1, 'disabled-row': !button.isRadioEnabled}">
            <div class="d-flex align-items-center">
              <i class="{{ button.icon }} mr-2" [ngClass]="{'disabled-icon': !button.isRadioEnabled}"></i>
              <span class="label-style" [ngClass]="{'disabled-text': !button.isRadioEnabled}">{{ button.label |
                translate }}</span>
            </div>
          </td>
          <td class="td-enterprise-section">
            <div class="enterprise-section">
              <section>
                <mat-radio-button [checked]="button.selectedButton === 1" [disabled]="!button.isRadioEnabled"
                  (click)="onRadioChange(1, button)"></mat-radio-button>
              </section>
            </div>
          </td>
          <td class="slide-toggle-style">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <mat-slide-toggle (change)="onSlideToggleChange($event, button)"
                  [checked]="button.isSlideToggleEnabled"></mat-slide-toggle>
              </div>
              <i class="fas fa-grip-vertical"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
