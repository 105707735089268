<div class="row justify-content-end list-group-1920">
	<div class="col-3" *ngIf="item.numGroups !== undefined">
		<div
			class="d-flex align-items-center justify-content-center flex-column list-content"
			style="border-right: solid 1px #d9d9d9"
		>
			<span class="d-flex align-items-center">
				{{ item.numGroups | number }}
				<i class="fal fa-layer-group"></i>
			</span>
			<h5 class="text-lowercase" translate>group.groups</h5>
		</div>
	</div>
	<div class="col-3" *ngIf="item.numCardholders !== undefined">
		<div
			class="d-flex align-items-center justify-content-center flex-column list-content"
			style="border-right: solid 1px #d9d9d9"
		>
			<span class="d-flex align-items-center">
				{{ item.numCardholders | number }}
				<i class="fal fa-clipboard-user"></i>
			</span>
			<h5 class="text-lowercase" translate>cardholder.cards</h5>
		</div>
	</div>
	<div class="col-3">
		<div
			class="d-flex align-items-center justify-content-center flex-column list-content"
			style="border-right: solid 1px #d9d9d9"
		>
			<span class="d-flex align-items-center">
				{{ (item.pageviews > 0 ? item.pageviews : 0) | number }}
				<i class="far fa-eye"></i>
			</span>
			<h5 class="text-lowercase" translate>stats.views</h5>
		</div>
	</div>
	<div class="col-3">
		<div class="d-flex align-items-center justify-content-center flex-column list-content">
			<span class="d-flex align-items-center">
				{{ (item.clicks > 0 ? item.clicks : 0) | number }}
				<i class="fas fa-bullseye-pointer"></i>
			</span>
			<h5 class="text-lowercase" translate>stats.clicks</h5>
		</div>
	</div>
</div>

<div class="list-group-360">
	<div class="group-info group-info-start" *ngIf="item.numGroups !== undefined">
		<span class="d-flex align-items-center">
			{{ item.numGroups | number }}
			<i class="fal fa-layer-group"></i>
		</span>
		<h5 class="text-lowercase m-0" translate>group.groups</h5>
	</div>

	<div class="group-info group-info-start" *ngIf="item.numCardholders !== undefined">
		<span class="d-flex align-items-center">
			{{ item.numCardholders | number }}
			<i class="fal fa-clipboard-user"></i>
		</span>
		<h5 class="text-lowercase m-0" translate>cardholder.cards</h5>
	</div>

	<div class="group-info group-info-start">
		<span class="d-flex align-items-center">
			{{ (item.pageviews > 0 ? item.pageviews : 0) | number }}
			<i class="far fa-eye"></i>
		</span>
		<h5 class="text-lowercase m-0" translate>stats.views</h5>
	</div>

	<div class="group-info">
		<span class="d-flex align-items-center">
			{{ (item.clicks > 0 ? item.clicks : 0) | number }}
			<i class="fas fa-bullseye-pointer"></i>
		</span>
		<h5 class="text-lowercase m-0" translate>stats.clicks</h5>
	</div>
</div>
