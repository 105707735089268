import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInfo } from '@app-store/session/session.store';
import { SessionService } from '@app-store/session/session.service';
import { SessionQuery } from '@app-store/session/session.query';
import { SidebarService } from '@app-services/sidebar.service';
import { ProductService } from '@app-services/product.service';
import { ProductInfo } from '@app-model/product';

@Component({
	selector: 'app-layout-restrict-area-header',
	templateUrl: './layout-restrict-area-header.component.html',
	styleUrls: ['./layout-restrict-area-header.component.scss']
})
export class LayoutRestrictAreaHeaderComponent implements OnInit {
	isAuthenticated$: Observable<boolean>;
	userInfo: UserInfo;

	notifications: any[];
	productInfo: ProductInfo;


	constructor(
		private sidebarService: SidebarService,
		private sessionService: SessionService,
		private sessionQuery: SessionQuery,
		private productService: ProductService
	) {
		this.notifications = [
			{
				icon: 'i-Speach-Bubble-6',
				title: 'Nova mensagem',
				text: 'Lorem ipsum',
				time: new Date(),
				status: 'primary'
			},
			{
				icon: 'i-Receipt-3',
				title: 'Novo serviço',
				text: 'Lorem ipsum',
				time: new Date('11/11/2018'),
				status: 'success'
			},
			{
				icon: 'i-Empty-Box',
				title: 'Nova peça',
				text: 'Lorem ipsum',
				time: new Date('11/10/2018'),
				status: 'danger'
			}
		];
	}

	ngOnInit() {
		this.productService.getProductInfo().subscribe(p => this.productInfo = p);
		this.isAuthenticated$ = this.sessionQuery.isAuthenticated$;
		this.sessionQuery.userInfo$.subscribe(u => this.userInfo = u);
	}

	toggelSidebar() {
		this.sidebarService.active = !this.sidebarService.active;
	}

	signout() {
		this.sessionService.logout();
	}

}
