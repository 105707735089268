import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { FileUploaderService } from '@app-services/file-uploader.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { Product, ProductStatus } from '@app-model/product';
import { ProductService } from '@app-services/product.service';
import * as moment from 'moment';
import * as Masks from '@app-shared/masks';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  @ViewChild('typeInput') typeInput: ElementRef<HTMLInputElement>;
	product: Product;
	loading = false;
	loadingProduct = false;

	uploader$: Observable<FileUploader>;
	form: FormGroup;

	statusProduct$: Observable<ProductStatus[]>;
	loadingText: string;

	mask = {
		guide: true,
		showMask: true,
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
	};


	searchType = new FormControl();
	filteredTypes: Observable<any[]>;
	listTypes: any[] = [];
	matChipInputSeparatorKeyCodes = [ENTER, COMMA];

	constructor(
		private fileUploaderService: FileUploaderService,
		private fb: FormBuilder,
		private productService: ProductService,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private router: Router
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			id: [{ value: null, disabled: true }],
			name: ['', [Validators.required]],
			value: [0, [Validators.required]],
			statusId: ['', [Validators.required]],
			init: ['', [Validators.required]],
			end: ['', [Validators.required]],
			active: [true],
			option: this.fb.array([]),
			types: this.fb.array([]),
			typeList: this.fb.array([]),
			description: ['', [Validators.required]],
			files: this.fb.array([])
		});

		this.statusProduct$ = this.productService.getStatusProduct();

		const id = this.route.snapshot.params.id as string;
		if (!!id) {
			this.loadingProduct = true;
			this.productService.get(id).subscribe(
				product => {
					this.loadingProduct = false;
					this.product = product;
					this.form.patchValue({
						...this.product
					});

					if (this.product.files) {
						this.product.files.forEach(file => {
							(this.form.get('files') as FormArray).push(this.createFileGroup(file));
						});
					}

					if (this.product.option) {
						this.product.option.forEach(opt => {
							(this.form.get('option') as FormArray).push(this.fb.control(opt));
						});
					}
				},
				err => {
					this.loadingProduct = false;
				}
			);
		}

		this.initFileUploader();
	}

	private async initFileUploader() {
		this.uploader$ = this.fileUploaderService.getInstance({
			onCompleteItem: this.onCompleteUploadFileItem
		});
	}

	onCompleteUploadFileItem = (fileItem: FileItem, response: any, status) => {
		if (response && response.fileUrl) {
			const files = this.form.get('files') as FormArray;
			files.push(
				this.createFileGroup({
					fileName: fileItem.file.name,
					fileSize: fileItem.file.size,
					fileUrl: response.fileUrl
				})
			);
			fileItem.remove();
		}
	};

	private createFileGroup(file: any) {
		return this.fb.group({
			fileName: file.fileName,
			fileSize: file.fileSize,
			fileUrl: file.fileUrl
		});
	}

	get files(): FormArray {
		return this.form.get('files') as FormArray;
	}

	optionChecked(value) {
		const options = this.form.get('option') as FormArray;
		return options.controls.filter(control => control.value === value).length > 0;
	}

	onChangeOption(event) {
		const { source, checked } = event;
		const { value } = source;
		const options = this.form.get('option') as FormArray;
		if (checked) {
			options.push(this.fb.control(value));
		} else {
			const index = options.controls.findIndex(control => control.value === value);
			options.removeAt(index);
		}
	}

	get types(): FormArray {
		return this.form.get('typeList') as FormArray;
	}

	optionSelected(e: MatAutocompleteSelectedEvent): void {
		const exists = this.types.value
		.find(item => item.name === e.option?.value?.name);

		!exists ? this.types.push(this.fb.control(e.option.value)) : this.toastr.warning('', 'Tipo já adicionado');

		this.typeInput.nativeElement.value = '';
		this.searchType.setValue(null);
	}

	matChipInputTokenEnd(e: MatChipInputEvent): void{
		const input = e.input;
		const value = e.value;

		const existsForm = this.types.value
		.find(item => item.name.toLowerCase() === e.value.toLowerCase());

		const existsList = this.listTypes
		.find(item => item.name.toLowerCase() === e.value.toLowerCase());

		if ((value || '').trim()) {
			if(!existsForm) {
				if(!existsList ) {
					this.types.push(this.fb.control({id: null, name: value.trim()}));
				} else {
					this.types.push(this.fb.control(existsList));
				}

			} else {
				this.toastr.warning('', 'Tipo já adicionado');
			}
		}

		input && (input.value = '');

		this.searchType.setValue(null);
	}

	removed(type: string): void {
		const index = this.types.controls.findIndex(control => control.value === type);
		index >= 0 && this.types.removeAt(index);
	}

	onSubmit(values) {
		this.loadingText = 'Salvando...';
		this.loading = true;
		if (typeof values.init === 'string' && values.init.indexOf('/') > -1) {
			values.init = moment(values.init, 'DD/MM/YYYY').toDate();
		}

		if (typeof values.end === 'string' && values.end.indexOf('/') > -1) {
			values.end = moment(values.end, 'DD/MM/YYYY').toDate();
		}

		this.productService.saveOrUpdate(values).subscribe(
			response => {
				this.loading = false;
				if (response.success) {
					this.toastr.success(
						'',
						this.product.id ? 'Produto atualizado com sucesso' : 'Produto cadastrado com sucesso'
					);
					this.router.navigate(['/painel/product']);
				} else {
					this.toastr.error('', 'Falha ao salvar o produto');
				}
			},
			err => {
				this.loading = false;
				this.toastr.error('', 'Falha ao salvar o produto');
			}
		);
	}

}
