import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MetaTagService {
	updateFavicon(document: Document, faviconUrl: string): void {
		if (faviconUrl && document) {
			const head = document.getElementsByTagName('head')[0];
			this.setFaviconElement(document, head, 'icon', faviconUrl);
			this.setFaviconElement(document, head, 'apple-touch-icon', faviconUrl);
		}
	}

	private setFaviconElement(document: Document, head: HTMLHeadElement, relName: string, faviconUrl: string): void {
		let element: HTMLLinkElement = document.querySelector(`link[rel='${relName}']`) || null;
		if (element == null) {
			element = document.createElement('link') as HTMLLinkElement;
			head.appendChild(element);
		}
		element.setAttribute('rel', relName);
		element.setAttribute('href', faviconUrl);
	}
}
