
<ng-container>
  <div class="content-body-drawer">
    <div class="row pt-4 pl-3 pb-3 pr-3">
      <div class="col-4">
        <button mat-flat-button class="btn btn-apply" (click)="handlerFilter()">
          <i class="fal fa-check"></i>
          {{'apply' | translate}}
        </button>
      </div>
      <div class="col-4 text-right">
        <button mat-stroked-button class="btn btn-cancel" (click)="cleanAllCheckboxes()">
          <i class="fal fa-trash-alt mr-1"></i>
          {{'clean_all' | translate}}
        </button>
      </div>
      <div class="col-4 text-right">
        <button mat-stroked-button class="btn btn-cancel" (click)="closeFilter()">
          {{'cancel' | translate}}
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <section *ngIf="!showCheckedBox">
      <ng-container *ngFor="let key of ['clients', 'groups', 'cardholders']">
        <ng-container  *ngIf="postData[key]?.length > 1 && getFilteredItems(key)?.length > 0">
          <div class="item-title-container d-flex justify-content-between align-items-center">
            <div><h1 class="m-0">{{ 'menu.' + key | translate }}</h1></div>
            <div class="div-clear-checkbox d-flex justify-content-center align-items-center" (click)="handlerCleanCategoryCheckbox(key)">
              <i class="fal fa-trash-alt mr-1"></i>
              <span class="m-0">{{ 'clear' | translate }}</span>
            </div>
          </div>
          <section class="section-selected-box">
            <mat-checkbox
              class="example-margin"
              *ngFor="let item of getFilteredItems(key)"
              [checked]="item.checked"
              (change)="onCheckboxChange(key, item, $event)"
            >
              <span>{{ item.name }}</span>
            </mat-checkbox>
          </section>
        </ng-container>
      </ng-container>

    </section>
  </div>
</ng-container>
