import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';

import { Observable, from, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { AmplifyService } from './amplify.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(private amplifyService: AmplifyService, @Inject(PLATFORM_ID) private readonly platformId: string) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (isPlatformServer(this.platformId)) {
			let headers = request.headers;

			headers = headers.append('X-MBC-Origin', window.origin);
			request = request.clone({ headers });
		}

		if (request.headers.has(InterceptorSkipHeader)) {
			return next.handle(this.clearHeaders(request));
		}

		return from(this.amplifyService.isAuthenticated()).pipe(
			mergeMap(isAuthenticate => {
				if (isAuthenticate) {
					return this.amplifyService.getJwt().pipe(
						mergeMap(jwt => {
							return this.setJwtOrSkip(request, next, jwt);
						}),
						catchError(err => {
							if (err.status === 0) {
								return throwError(err);
							}
							return throwError(err);
						})
					);
				}
				const headers = request.headers.delete(InterceptorSkipHeader);
				request = request.clone({ headers });
				return next.handle(request);
			})
		);
	}

	private clearHeaders(request: HttpRequest<any>): HttpRequest<any> {
		const headers = request.headers.delete(InterceptorSkipHeader);
		request = request.clone({ headers });
		return request;
	}

	private setJwtOrSkip(request: HttpRequest<any>, next: HttpHandler, jwt: string): Observable<HttpEvent<any>> {
		let headers = request.headers;

		if (!request.headers.has(InterceptorSkipHeader)) {
			headers = headers.append('Authorization', `Bearer ${jwt}`);
		}


		headers = headers.delete(InterceptorSkipHeader);
		request = request.clone({ headers });

		return next.handle(request);
	}
}
