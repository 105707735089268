import { Component, Input, OnInit } from '@angular/core';
import { Chart, registerables, LayoutPosition, TooltipModel } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import 'chartjs-plugin-annotation';
import { DecimalPipe } from '@angular/common';
import { ProductService } from '@app-services/product.service';
Chart.register(...registerables);

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @Input() labels: string[];
  @Input() label: string;
  @Input() data: number[];
  @Input() averageData: number[];
  @Input() typeChart: any = 'line'
  @Input() positionChart: LayoutPosition = "bottom"

  labelLine: string;
  labelAverage: string;
  stepSize: number;

  private decimalPipe: DecimalPipe;

  constructor(private translate: TranslateService, private productService: ProductService) {
  }

  ngOnInit(): void {
    this.productService.getProductInfo().subscribe(p => {
      this.decimalPipe = new DecimalPipe(p.language);
    });

    this.translate.get(`stats.${this.label}`).subscribe(s => this.labelLine = s);
    this.translate.get('dashboard.average_value').subscribe(s => this.labelAverage = s);

    if (this.data !== undefined && this.labels !== undefined) {
      this.handleInitChartjs()
    }

  }

  private handleInitChartjs(): void {

    new Chart("ChartLineComponent", {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: [
          {
            label: this.labelLine,
            data: this.data,
            fill: true,
            borderColor: '#F18700',
            backgroundColor: 'rgba(235, 199, 10, 0.3)',
            borderWidth: 2,
            pointBackgroundColor: '#F18700',
            pointRadius: 5,
          },
          {
            label: this.labelAverage,
            data: this.averageData,
            type: 'line',
            fill: false,
            borderColor: '#00455A',
            pointBackgroundColor: '#00455A',
            borderWidth: 2,
            borderDash: [5, 5],
            pointRadius: 0
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: this.positionChart,
            display: true,
            labels: {
              padding: 20,
              font: {
                size: 14,
                weight: '400',
                lineHeight: 22,
                family: 'Roboto'
              },
              usePointStyle: true,
              boxHeight: 7,
              pointStyleWidth: 10,
            }
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = context.parsed.y;
                return context.dataset.label + ': ' + this.decimalPipe.transform(value, value > 10 || Number.isInteger(value) ? '0.0-0' : '0.1-1');
              },
            },
          },
          datalabels: {
            display: false
          }
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
            title: {
              display: false,
            },
            ticks: {
              maxTicksLimit: 5,
              stepSize: 4, // Defina o intervalo dos valores no eixo Y
              callback: function (value) {
                return value.toString();
              },
            },
          },
        },
      },
    });
  }


}
