import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';

import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { ListLegendComponent } from './list-legend/list-legend.component';
import { LoaderComponent } from './loader/loader.component';
import { Ng2FileDropComponent } from './ng2-file-drop/ng2-file-drop.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { StatsListItemComponent } from './stats-list-item/stats-list-item.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DragDropLinksComponent } from './drag-and-drop/drag-drop-links/drag-drop-links.component';
import { DragDropEnabledLinksComponent } from './drag-and-drop/drag-drop-enabled-links/drag-drop-enabled-links.component';
import { CustomRangePanelComponent } from './custom-date-range-picker/custom-range-panel/custom-range-panel.component';
import { CustomHeaderDateRangePickerComponent } from './custom-date-range-picker/custom-header-date-range-picker/custom-header-date-range-picker.component';
import { DoughnutPieChartComponent } from './charts/doughnut-pie-chart/doughnut-pie-chart.component';
import { DoughnutPieChartLeastClickedComponent } from './charts/doughnut-pie-chart-least-clicked/doughnut-pie-chart-least-clicked.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component'
import { ChartLegendComponent } from './chart-legend/chart-legend.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { translateServerLoaderFactory } from '@app-utils/translate-server-loader';
import { CreditCardIconComponent } from './credit-card-brand/credit-card-icon.component';
import { QRCodeComponent } from './qr-code/qr-code.component';
import { QRCodeModule } from 'angularx-qrcode';
import { BtnLoadMoreComponent } from './btn-load-more/btn-load-more.component';


const components = [
  BtnLoadingComponent,
  Ng2FileDropComponent,
  LoaderComponent,
  StarRatingComponent,
  ListLegendComponent,
  StatsListItemComponent,
  DragDropLinksComponent,
  DragDropEnabledLinksComponent,
  CustomRangePanelComponent,
  CustomHeaderDateRangePickerComponent,
  DoughnutPieChartComponent,
  DoughnutPieChartLeastClickedComponent,
  LineChartComponent,
  BarChartComponent,
  ChartLegendComponent,
  CreditCardIconComponent,
  QRCodeComponent,
  BtnLoadMoreComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedPipesModule,
    SharedDirectivesModule,
    PerfectScrollbarModule,
    NgbModule,
    FileUploadModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateServerLoaderFactory),
        deps: [HttpClient]
      }
    }),
    DragDropModule,
    MatInputModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatCardModule,
    MatIconModule,
    MatSidenavModule,
    QRCodeModule
  ],
  declarations: components,
  exports: components
})
export class SharedComponentsModule { }
