import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { take, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SessionService } from 'src/app/store/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private sessionService: SessionService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const result = this.sessionService.getUser().pipe(
      take(1),
      switchMap(user => {
        try {
          const { roles } = route.data;
          const hasRole = user.hasAnyRole(...roles);

          if (hasRole) {
            return of(true);
          }

          this.router.navigate(['/']);
          return of(false);
        } catch (error) {
          console.error(`error checking roleGuard: ${error}`)
          return of(false);
        }

      })
    );

    return result;
  }
}
