
<app-custom-range-panel></app-custom-range-panel>
<div class="data-picker-header">

  <button
    mat-icon-button
    class="button-double-arrow mr-1"
    (click)="previousClicked('year')"
  >
    <i class="fal fa-chevron-double-left"></i>
  </button>

  <button mat-icon-button class="button-arrow" (click)="previousClicked('month')">
    <i class="fal fa-angle-left"></i>
  </button>

  <span class="header-label">{{ periodLabel }}</span>

  <button mat-icon-button class="button-arrow" (click)="nextClicked('month')">
    <i class="fal fa-angle-right"></i>
  </button>

  <button
    mat-icon-button
    class="button-double-arrow ml-1"
    (click)="nextClicked('year')"
  >
  <i class="fal fa-chevron-double-right"></i>
  </button>
</div>
<div class="row div-day-week">
  <div class="col-auto" *ngFor="let day of getWeekdays()">{{ day }}</div>
</div>
