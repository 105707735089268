import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormArray } from '@angular/forms';
import { TypeButtons, LinkType, LinkUtils } from '@app-model/businessCard';
import { Client } from '@app-model/client';
import { ProductService } from '@app-services/product.service';

class DisplayButton extends TypeButtons {
  isRadioEnabled: boolean;
}

@Component({
  selector: 'app-drag-drop-enabled-links',
  templateUrl: './drag-drop-enabled-links.component.html',
  styleUrls: ['./drag-drop-enabled-links.component.scss']
})
export class DragDropEnabledLinksComponent implements OnInit {

  allButtons: TypeButtons[] = [];

  @Input() enabledLinks: FormArray;
  @Input() initialFormValue: Client;
  @Output() updateFormArray: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateLinksOrder: EventEmitter<LinkType[]> = new EventEmitter<LinkType[]>();
  @Output() inputChange: EventEmitter<{ button: any, value: string }> = new EventEmitter<{ button: any, value: string }>();
  @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();

  public isSlideToggleEnabled = false;
  public displayButtons: DisplayButton[] = [];
  public radioSelected: DisplayButton | null = null;

  constructor(private productService: ProductService) {

  }

  public ngOnInit(): void {
    this.productService.getProductInfo().subscribe(product => {

      this.allButtons = LinkUtils.buildLinksArray(product);
      this.displayButtons = this.allButtons.map(button => ({
        ...button,
        isRadioEnabled: false,
        selectedButton: null,
        isSlideToggleEnabled: this.initialFormValue?.layoutScheme?.enabledLinks.includes(button.type) || false
      }));

      if (this.initialFormValue && this.initialFormValue.layoutScheme && this.initialFormValue.layoutScheme.enabledLinks) {

        const enabledLinks = this.initialFormValue.layoutScheme.enabledLinks;
        const firstEnabledButtonType = enabledLinks.find(link => this.allButtons.some(button => button.type === link));

        const sortedButtons: DisplayButton[] = [];

        enabledLinks.forEach(link => {
          const button = this.displayButtons.find(btn => btn.type === link);
          if (button) {
            sortedButtons.push(button);
          }
        });

        this.displayButtons.forEach(button => {
          if (!enabledLinks.includes(button.type)) {
            sortedButtons.push(button);
          }
        });

        this.displayButtons = sortedButtons;

        const firstEnabledButton = this.displayButtons.find(button => button.type === firstEnabledButtonType);
        if (firstEnabledButton) {
          firstEnabledButton.selectedButton = 1;
          firstEnabledButton.isRadioEnabled = true
        }

        this.displayButtons.forEach(button => {
          button.isRadioEnabled = this.initialFormValue.layoutScheme.enabledLinks.includes(button.type);
        });
      }
    });
  }

  public drop(event: CdkDragDrop<DisplayButton[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    if (this.radioSelected) {
      const selectedButtonIndex = this.displayButtons.findIndex(button => button.type === this.radioSelected!.type);
      if (selectedButtonIndex !== -1) {
        this.displayButtons.splice(selectedButtonIndex, 1);
        this.displayButtons.unshift(this.radioSelected);
      }
    }

    this.updateFormArray.emit();
    this.saveLinksOrder();
  }

  public saveLinksOrder(): void {
    const orderedLinks = this.displayButtons
      .filter(button => button.isRadioEnabled)
      .map(button => button.type);
    this.updateLinksOrder.emit(orderedLinks);
  }

  public onRadioChange(selectedButton: number, button: DisplayButton): void {
    if (button.isRadioEnabled) {
      button.selectedButton = selectedButton;
      this.displayButtons = this.displayButtons.filter((btn) => btn.type !== button.type);
      this.displayButtons.unshift(button);
      this.radioSelected = button;
      this.saveLinksOrder();
    }
  }

  public onSlideToggleChange(event: any, button: DisplayButton): void {
    const previousRadioSelected = this.radioSelected;
    button.isRadioEnabled = event.checked;
    if (!button.isRadioEnabled) {
      button.selectedButton = 0;
    }
    if (previousRadioSelected && previousRadioSelected.type === button.type) {
      this.radioSelected = null;
    }
    this.saveLinksOrder();
  }

}
