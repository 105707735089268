import { Component, OnInit } from '@angular/core';
import { ProductService } from '@app-services/product.service';
import { ClientService } from '@app-services/client.service';
import { PageControl } from '@app-model/page-control';
import { Product } from '@app-model/product';
import { ClientList } from '@app-model/client';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-clients',
	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

	loading = false;
	openFilter = false;
	searchTem = '';

	list: ClientList[] = [];

	columns = [
		{
			slug: 'name',
			order: true,
			title: 'PRODUTO'
		},
		{
			slug: 'description',
			order: true,
			title: 'DESCRIÇÃO'
		},
		{
			slug: 'value',
			order: true,
			title: 'VALOR'
		},
		{
			slug: 'end',
			order: true,
			title: 'VALIDADE'
		},
		{
			slug: 'init',
			order: true,
			title: 'ENTRADA'
		}
	];

	pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'name',
		order: 'DESC',
		count: 0
	};

	constructor(private clientService: ClientService, private translate: TranslateService) { }

	ngOnInit() {
		this.getResults();
	}

	onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.getResults();
	}

	private getResults() {
		this.loading = true;

		this.clientService.paginate(this.pageControl).subscribe(
			response => {
				this.pageControl.count = response.totalElements;
				this.pageControl.hasMore = response.totalElements > ((response.actualPage + 1) * this.pageControl.limit);
				this.list.push(...response.data);
				this.loading = false;
			},
			err => {
				this.loading = false;
				this.list = [];
				this.pageControl.count = 0;
			}
		);
	}

	onSearch() {
		this.pageControl.value = this.searchTem;
		this.pageControl.param = 'description';
		this.pageControl.page = 0;
		this.getResults();
	}

	loadMore(): void {
		this.pageControl.page++;
		this.getResults();
	}

}
