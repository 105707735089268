import { Component, Input, OnInit } from '@angular/core';
import { ProductInfo } from '@app-model/product';
import { ProductService } from '@app-services/product.service';

@Component({
  selector: 'app-layout-restrict-area-footer',
  templateUrl: './layout-restrict-area-footer.component.html',
  styleUrls: ['./layout-restrict-area-footer.component.scss']
})
export class LayoutRestrictAreaFooterComponent implements OnInit {

  productInfo: ProductInfo;

  constructor(private productService: ProductService) {
  }

  ngOnInit(): void {
    this.productService.getProductInfo().subscribe(p => this.productInfo = p);
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

}
