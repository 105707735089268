import { Component, OnDestroy, OnInit } from '@angular/core';
import { Cardholder, Client, DashboardData, DashboardDataIds, Group, PostData } from '@app-model/dashboard';
import { FilterService } from '@app-services/filter.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-side-filter',
  templateUrl: './side-filter.component.html',
  styleUrls: ['./side-filter.component.scss']
})
export class SideFilterComponent implements OnInit, OnDestroy {

  showFiller: boolean = false;
  showCheckedBox: boolean = false;

  public postData: DashboardData = {
    clients: [],
    groups: [],
    cardholders: [],
  };

  private subscription: Subscription;

  selectedIds: DashboardData = {
    clients: [],
    groups: [],
    cardholders: [],
  }

  constructor(
    private readonly filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.subscription = this.filterService.postData$.pipe(finalize(() => this.showCheckedBox = true)).subscribe(data => {
      this.postData = {
        clients: data?.clients,
        groups: data?.groups,
        cardholders: data?.cardholders,
      };
    })
  }

  onCheckboxChange(categoryKey: string, item, event) {
    if (event.checked) {
      this.selectedIds[categoryKey].push(item);
    } else {
      const index = this.selectedIds[categoryKey].indexOf(item);
      if (index > -1) {
        this.selectedIds[categoryKey].splice(index, 1);
      }
    }

    const postDataCategory = this.postData[categoryKey];
    const selectedItem = postDataCategory.find(dataItem => dataItem.id === item.id);
    if (selectedItem) {
      selectedItem.checked = event.checked;
    }

    this.clearFilteredOutSelectedItems();
  }

  public handlerFilter(): void {
    this.filterService.updateSelectedIds(this.selectedIds);
    this.closeFilter();
  }

  public closeFilter(): void {
    this.filterService.closeFilter();
  }

  public cleanAllCheckboxes(): void {
    for (const category of Object.keys(this.postData)) {
      for (const item of this.postData[category]) {
        item.checked = false;
      }
      this.selectedIds[category] = [];
    }
  }

  public handlerCleanCategoryCheckbox(TypeCategory: string): void {
    for (const item of this.postData[TypeCategory]) {
      item.checked = false;
    }
    this.selectedIds[TypeCategory] = [];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getFilteredItems(key: string): Client[] | Group[] | Cardholder[] {
    switch (key) {
      case 'clients': return this.postData.clients;
      case 'groups': return this.filteredGroups();
      case 'cardholders': return this.filteredCardholders();
    }
  }

  private filteredCardholders(): Cardholder[] {
    const selectedClientIds = this.selectedIds.clients?.map(c => c.id);
    const selectedGroupIds = this.selectedIds.groups?.map(g => g.id);

    return this.postData.cardholders.filter(c => {
      let okClient = selectedClientIds?.length <= 0 || selectedClientIds.indexOf(c.client?.id) >= 0;
      let okGroup = selectedGroupIds?.length <= 0 || selectedGroupIds.indexOf(c.group?.id) >= 0;

      return okClient && okGroup;
    });

  }

  private filteredGroups(): Group[] {
    const selectedClientIds = this.selectedIds.clients?.map(c => c.id);

    if (selectedClientIds?.length > 0) {
      return this.postData.groups.filter(g => selectedClientIds.indexOf(g.client?.id) >= 0);
    } else {
      return this.postData.groups;
    }

  }

  private clearFilteredOutSelectedItems(): void {
    const selectedClientIds = this.selectedIds.clients?.map(c => c.id);

    this.selectedIds.groups = this.selectedIds.groups?.filter(g => {
      if (selectedClientIds.length > 0 && selectedClientIds.indexOf(g.client?.id) < 0) {
        this.postData.groups.find(checkbox => checkbox.id === g.id)['checked'] = false;
        return false;
      }

      return true;
    })

    const selectedGroupIds = this.selectedIds.groups?.map(g => g.id);

    this.selectedIds.cardholders = this.selectedIds.cardholders?.filter(c => {
      if ((selectedClientIds.length > 0 && selectedClientIds.indexOf(c.client?.id) < 0)
        || (selectedGroupIds.length > 0 && selectedGroupIds.indexOf(c.group?.id) < 0)) {

        this.postData.cardholders.find(checkbox => checkbox.id === c.id)['checked'] = false;
        return false;
      }

      return true;
    })
  }



}
