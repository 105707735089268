<mat-drawer-container #drawer [hasBackdrop]="true">

  <mat-drawer-content>
    <div [ngTemplateOutlet]="layoutRestrict"></div>
  </mat-drawer-content>

  <mat-drawer mode="side" position="end" mode="over" [opened]="filterStatus$ | async"
    (openedChange)="handleFilterStatus($event)" style="max-width: 380px; width: 100%;">
    <app-side-filter></app-side-filter>
  </mat-drawer>
</mat-drawer-container>

<ng-template #layoutRestrict>
  <div>
    <app-layout-restrict-area-header></app-layout-restrict-area-header>
    <div class="wrapper d-flex align-items-stretch">
      <div class="handle-responsive-sidevar">
        <app-layout-restrict-area-sidebar></app-layout-restrict-area-sidebar>
      </div>
      <div id="content" class="p-4 p-md-5 pt-5" [ngClass]="{ 'sidebar-active': sidebarService.active }">
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
        <div class="module-loader" *ngIf="moduleLoading">
          <div class="spinner spinner-bubble spinner-bubble-primary mr-3"></div>
        </div>
      </div>
    </div>
  </div>
  <app-layout-restrict-area-footer></app-layout-restrict-area-footer>
</ng-template>


