<nav
	id="sidebar"
	[ngClass]="{ active: sidebarService.active, 'toggle-sidebar': sidebarService.minSidebar }"
	*ngIf="!hideMenu()"
	[perfectScrollbar]="{ suppressScrollX: true }"
>
	<div id="sidebar-container" [ngStyle]="{ padding: !sidebarService.minSidebar ? '0 15px' : '0' }">
		<ul class="list-unstyled components">
			<ng-container *ngFor="let item of itemsMenu">
				<li
					routerLinkActive="active"
					[ngClass]="{ 'dropdown-active': item.dropdown }"
					*ngIf="user?.hasAnyRoleArr(item.roles)"
				>
					<a
						[ngClass]="{ 'justify-content-center': sidebarService.minSidebar }"
						*ngIf="item.type === 'link'"
						[routerLink]="[item.link]"
						routerLinkActive="active"
					>
						<i class="{{ item.icon }}"></i>
						<span *ngIf="!sidebarService.minSidebar">{{ item.label | translate }}</span>
					</a>
					<ng-container *ngIf="item.type === 'dropdown'">
						<a
							[ngClass]="{ 'justify-content-center': sidebarService.minSidebar }"
							data-toggle="collapse"
							(click)="setActiveMenu(item)"
							[attr.aria-expanded]="item.dropdown"
							class="dropdown-toggle"
						>
							<i class="{{ item.icon }}"></i>
							<span *ngIf="!sidebarService.minSidebar">{{ item.label | translate }}</span>
							<i *ngIf="!sidebarService.minSidebar" class="fal fa-angle-right icon-dropdown mr-3 ml-4"></i>
						</a>
						<ul
							class="collapse list-unstyled"
							id="{{ item.id }}"
							[ngClass]="{ show: item.dropdown, 'ml-0': sidebarService.minSidebar }"
						>
							<li *ngFor="let children of item.children">
								<a
									[ngClass]="{ 'justify-content-center': sidebarService.minSidebar }"
									[routerLink]="[children.link]"
									routerLinkActive="active"
									*ngIf="!children.roles || user?.hasAnyRoleArr(children.roles)"
								>
									<span *ngIf="!sidebarService.minSidebar">{{ children.label | translate }}</span>
									<span *ngIf="sidebarService.minSidebar">
										<i class="fal fa-chevron-circle-right icon-min-sidebar"></i>
									</span>
								</a>
							</li>
						</ul>
					</ng-container>
				</li>
			</ng-container>
		</ul>
		<ul class="list-unstyled components ul-toggle-sidebar" *ngIf="isMobile">
			<li *ngIf="!sidebarService.minSidebar">
				<a (click)="toggleSidebar()" role="button" routerLinkActive="active">
					<i class="fal fa-angle-left"></i>
					<span>{{ 'menu.collapse' | translate }}</span>
				</a>
			</li>
			<li *ngIf="sidebarService.minSidebar">
				<a (click)="toggleSidebar()" class="justify-content-center" role="button">
					<i class="fal fa-angle-right"></i>
				</a>
			</li>
		</ul>
	</div>
</nav>
