<div class="breadcrumb">
	<div class="row">
		<div class="col d-flex align-items-center">
			<a>Home</a>
			<i class="material-icons">keyboard_arrow_right</i>
			<a [routerLink]="['/']">Produtos</a>
			<i class="material-icons">keyboard_arrow_right</i>
		</div>
	</div>
	<h1 class="mt-2">{{ product?.id ? 'Editando o produto ' + product.name : 'Novo produto' }}</h1>
	<div class="row" *ngIf="product">
		<div class="col mt-2">Criado {{ product.init | date: 'dd/MM/yyyy - HH:mm' }}</div>
	</div>
</div>

<div class="row">
	<div class="col-md-12">
		<div class="card mb-4">
			<div class="card-body">
				<mat-tab-group dynamicHeight>
					<mat-tab label="INFOMARÇÕES BÁSICAS">
						<div class="row" *ngIf="loadingProduct">
							<div class="col-md-12 mt-5 mb-5">
								<div class="loader-bubble loader-bubble-primary d-block"></div>
							</div>
						</div>

						<form [formGroup]="form" (validSubmit)="onSubmit($event)" appInvalidFormScroll *ngIf="!loadingProduct">
							<div class="margin-left-lg-6 margin-left-sm-1 col-12 col-12 col-lg-6">
								<div class="row mt-5">
									<div class="col-md-12">
										<mat-form-field>
											<input matInput placeholder="ID DO PRODUTO" value="" formControlName="id" />
										</mat-form-field>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-md-12">
										<mat-form-field>
											<input
												matInput
												currencyMask
												[options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
												placeholder="VALOR"
												formControlName="value"
											/>
										</mat-form-field>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-md-12">
										<mat-form-field>
											<mat-label>Status</mat-label>
											<mat-select formControlName="statusId">
												<mat-option *ngFor="let status of statusProduct$ | async" [value]="status.id">
													{{ status.description }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-md-6">
										<mat-form-field>
											<input
												mask="date"
												matInput
												[matDatepicker]="dateInit"
												placeholder="ENTRADA"
												formControlName="init"
											/>
											<mat-datepicker-toggle matSuffix [for]="dateInit"></mat-datepicker-toggle>
											<mat-datepicker #dateInit></mat-datepicker>
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field>
											<input
												mask="date"
												matInput
												[matDatepicker]="dateEnd"
												placeholder="VENCIMENTO"
												formControlName="end"
											/>
											<mat-datepicker-toggle matSuffix [for]="dateEnd"></mat-datepicker-toggle>
											<mat-datepicker #dateEnd></mat-datepicker>
										</mat-form-field>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-md-12">
										<label class="d-block">PRODUTO ATIVO</label>
										<mat-slide-toggle formControlName="active"
											>{{ form.get('active') ? 'SIM' : 'NÃO' }}
										</mat-slide-toggle>
									</div>
								</div>

								<div class="row mt-3">
									<div class="col-12">
										<label class="d-block">CHECKBOX</label>
									</div>
									<div class="col-12">
										<div class="row">
											<div class="col-md-6">
												<mat-checkbox
													class="d-block"
													value="BONITO"
													[checked]="optionChecked('BONITO')"
													(change)="onChangeOption($event)"
												>
													Bonito
												</mat-checkbox>
												<mat-checkbox
													class="d-block"
													value="BOM"
													[checked]="optionChecked('BOM')"
													(change)="onChangeOption($event)"
													>Bom custo benefício
												</mat-checkbox>
											</div>

											<div class="col-md-6">
												<mat-checkbox
													class="d-block"
													value="BARATO"
													[checked]="optionChecked('BARATO')"
													(change)="onChangeOption($event)"
												>
													Muito Barato
												</mat-checkbox>
												<mat-checkbox
													class="d-block"
													value="VENDIDO"
													[checked]="optionChecked('VENDIDO')"
													(change)="onChangeOption($event)"
												>
													Muito Vendido
												</mat-checkbox>
											</div>
										</div>
									</div>
								</div>

								<div class="row mt-3">
									<div class="col-md-12">
										<mat-form-field>
											<mat-label>Tipos</mat-label>
											<mat-chip-list #matChipList>
											  <mat-chip
												*ngFor="let type of types.value"
												[selectable]="true"
												[removable]="true"
												(removed)="removed(type)">
												{{type.name}}
												<mat-icon matChipRemove>cancel</mat-icon>
											  </mat-chip>
											  <input
												placeholder=""
												#typeInput
												[formControl]="searchType"
												[matAutocomplete]="auto"
												[matChipInputFor]="matChipList"
												[matChipInputSeparatorKeyCodes]="matChipInputSeparatorKeyCodes"
												(matChipInputTokenEnd)="matChipInputTokenEnd($event)"
												maxlength="50">
											</mat-chip-list>
											<mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
											  <mat-option *ngFor="let type of filteredTypes | async" [value]="type">
												{{type.name}}
											  </mat-option>
											</mat-autocomplete>
										  </mat-form-field>
									</div>
								</div>

								<div class="row mt-3">
									<div class="col-md-12">
										<mat-form-field>
											<textarea matInput placeholder="ÁREA DE TEXTO" formControlName="description"></textarea>
										</mat-form-field>
									</div>
								</div>

								<div class="row mt-3" *ngIf="uploader$ | async as uploader">
									<div class="col-12 col-md-12">
										<label> UPLOAD IMAGEM/VIDEO/AUDIO/ARQUIVO</label>
										<app-ng2-file-drop [uploader]="uploader"></app-ng2-file-drop>
										<div class="text-info-upload">Tamanho recomendado: 1920 x 1230 pixels</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-12">
										<div class="list-images">
											<div class="row">
												<div class="col-12 col-6 col-md-6 col-lg-4 mt-3">
													<div class="image-item">
														<img
															src="https://s3.amazonaws.com/wishe-files/project/pocdesign-bg-01.jpg"
															class="img-responsive"
														/>
														<button class="btn icon-button btn-outline-white">
															<i class="material-icons">delete</i>
														</button>
													</div>
												</div>
												<div class="col-12 col-6 col-md-6 col-lg-4 mt-3">
													<div class="image-item">
														<img
															src="https://s3.amazonaws.com/wishe-files/project/pocdesign-bg-01.jpg"
															class="img-responsive"
														/>
														<button class="btn icon-button btn-outline-white">
															<i class="material-icons">delete</i>
														</button>
													</div>
												</div>
												<div class="col-12 col-6 col-md-6 col-lg-4 mt-3">
													<div class="image-item">
														<img
															src="https://s3.amazonaws.com/wishe-files/project/pocdesign-bg-01.jpg"
															class="img-responsive"
														/>
														<button class="btn icon-button btn-outline-white">
															<i class="material-icons">delete</i>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="row mt-3">
									<div class="col-md-12">
										<ul class="list-upload-file">
											<li>
												<div class="row align-items-center">
													<div class="col-md-12 col-lg-8 name">
														nomedoarquivo.pdf (0,615mb)
													</div>
													<div class="col-10 col-md-10 col-lg-3 mr-auto loading">
														<div class="progress" style="height: 3px;">
															<div
																class="progress-bar"
																role="progressbar"
																style="width: 100%;"
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div class="col-2 col-md-2 col-lg-1 actions">
														<button><i class="material-icons">delete</i></button>
													</div>
												</div>
											</li>
											<li>
												<div class="row align-items-center">
													<div class="col-md-12 col-lg-8 name">
														nomedoarquivo.pdf (0,615mb)
													</div>
													<div class="col-10 col-md-10 col-lg-3 mr-auto loading">
														<div class="progress" style="height: 3px;">
															<div
																class="progress-bar"
																role="progressbar"
																style="width: 50%;"
																aria-valuenow="50"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div class="col-2 col-md-2 col-lg-1 actions">
														<button><i class="material-icons">delete</i></button>
													</div>
												</div>
											</li>
											<li>
												<div class="row align-items-center">
													<div class="col-md-12 col-lg-8 name">
														nomedoarquivo.pdf (0,615mb)
													</div>
													<div class="col-10 col-md-10 col-lg-3 mr-auto loading">
														<div class="progress" style="height: 3px;">
															<div
																class="progress-bar"
																role="progressbar"
																style="width: 50%;"
																aria-valuenow="50"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div class="col-2 col-md-2 col-lg-1 actions">
														<button><i class="material-icons">delete</i></button>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>

								<div class="history mt-4">
									<h5 class="mb-3">Histórico</h5>
									<ul class="historic-list">
										<li><small>20/05/2020 às 10:05</small><br /><span>Teste de historico</span></li>
										<li><small>20/05/2020 às 10:05</small><br /><span>Teste de historico</span></li>
										<li><small>20/05/2020 às 10:05</small><br /><span>Teste de historico</span></li>
										<li><small>20/05/2020 às 10:05</small><br /><span>Teste de historico</span></li>
									</ul>
								</div>
							</div>
							<div class="row mt-5">
								<div class="col">
									<btn-loading btnClass="btn-primary" [loadingText]="loadingText" [loading]="loading">
										Salvar
									</btn-loading>
								</div>
								<div class="col col-auto">
									<button type="button" class="btn btn-sm btn-outline-secondary">
										<i class="material-icons">delete</i>
										<span>EXCLUIR PRODUTO</span>
									</button>
								</div>
							</div>
						</form>
					</mat-tab>
					<mat-tab label="OUTRA TAB"> TAB2 </mat-tab>
					<mat-tab label="OUTRA TAB"> TAB3 </mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>
</div>
