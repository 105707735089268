import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-layout-blank',
	templateUrl: './layout-blank.component.html'
})
export class LayoutBlankComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
