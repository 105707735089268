<div class="mat-elevation-z3">
  <div class="div-button">
    <button
      *ngFor="let item of customPresets"
      [style.background]="buttonSelected === item ? '#f2f2f2' : null"
      mat-button
      (click)="selectRange(item)"
    >
      <span>{{ item | translate }}</span>
    </button>
  </div>
</div>
