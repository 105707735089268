import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { from, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError, switchMap, take, tap } from 'rxjs/operators';
import { ProductService } from './product.service';
import { CognitoConfig } from '@app-model/product';

@Injectable({
	providedIn: 'root'
})
export class AmplifyService {
	private configured = false;

	constructor(private productService: ProductService) {

	}

	private configure(): Observable<void> {
		if (this.configured) {
			return of(undefined);
		}

		return this.productService.getProductInfo()
			.pipe(
				take(1),
				map(product => {
					const config = product.cognitoConfig;
					Amplify.configure({
						Auth: {
							region: config.region,
							userPoolId: config.poolId,
							userPoolWebClientId: config.clientId
						}
					});

					this.configured = true;
					return;
				})
			);

	}


	/**
	 * Validar erros
	 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
	 */
	login(username: string, password: string): Observable<unknown> {
		return this.configure()
			.pipe(
				switchMap(p => from(Auth.signIn(username, password)))
			);
	}

	isAuthenticated(): Observable<boolean> {
		return this.configure()
			.pipe(
				switchMap(() => from(Auth.currentAuthenticatedUser()).pipe(
					map(user => {
						return !!user;
					}),
					catchError(err => of(false))
				))
			);
	}

	logout(): Observable<void> {
		return this.configure()
			.pipe(
				take(1),
				switchMap(() => from(Auth.signOut()))
			);
	}

	getJwt(): Observable<string | null> {
		return this.configure()
			.pipe(
				switchMap(() => from(Auth.currentSession()).pipe(
					map(response => {
						if (response.isValid()) {
							return response.getAccessToken().getJwtToken();
						}
						return null;
					})
				)
				)
			);
	}
}
