import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { ProductInfo } from '@app-model/product';
import { ProductService } from '@app-services/product.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-header-date-range-picker',
  templateUrl: './custom-header-date-range-picker.component.html',
  styleUrls: ['./custom-header-date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHeaderDateRangePickerComponent<D> implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<void>();

  private productInfo: ProductInfo;

  weekdaysBrazil: string[] = ['D','S', 'T', 'Q', 'Q', 'S', 'S'];
  weekdaysOtherCountry: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  constructor(
    private productService: ProductService,
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {

    calendar.stateChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => cdr.markForCheck()); //verificar mudanças no calendarios, um observable
  }

  ngOnInit(): void {
    this.productService.getProductInfo().subscribe(p => {
      this.productInfo = p;
    })
    this.dateAdapter.setLocale(this.productInfo?.language);
  }

  get periodLabel(): string {
    return this.dateAdapter
      .format(this.calendar.activeDate, {
        "year": "numeric",
        "month": "long"
    });
  }

  public previousClicked(mode: 'month' | 'year'): void {
    this.changeDate(mode, -1);
  }

  public nextClicked(mode: 'month' | 'year'): void {
    this.changeDate(mode, 1);
  }

  private changeDate(mode: 'month' | 'year', amount: -1 | 1): void {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, amount)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, amount);
  }

  public getWeekdays(): string[] {
    const location = this.productInfo ? this.productInfo?.language : '';
    return location === 'en-US' ? this.weekdaysOtherCountry : this.weekdaysBrazil;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

}
