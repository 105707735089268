<footer>
	<div class="container-fluid">
		<div class="wraper-menu-footer">
			<a [href]="productInfo?.logoDestinationUrl" target="_blank">
				<img [src]="productInfo?.secondaryLogoUrl" [alt]="productInfo?.name" height="24" />
			</a>
			<!-- <ul class="menu">
				<li><a [routerLink]="'/produtos'">Produtos</a></li>
				<li><a [routerLink]="'/sobre'">Sobre nós</a></li>
				<li><a [routerLink]="'/contato'">Contato</a></li>
			</ul> -->
		</div>
		<div class="rodape">
			<div class="copyright">© {{currentYear}}. {{'allRightsReserved' | translate}}</div>
			<ul>
				<li><a href="#" target="_blank">{{'termsAndConditions' | translate}}</a></li>
				<li><a href="#" target="_blank">{{'privacyPolicy' | translate}}</a></li>
				<!-- <li><a [routerLink]="'/login'">Acesso restrito</a></li> -->
			</ul>
		</div>
	</div>
</footer>
