import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatsListItem } from './stats-list-item';

@Component({
	selector: 'app-stats-list-item',
	templateUrl: './stats-list-item.component.html',
	styleUrls: ['./stats-list-item.component.scss']
})
export class StatsListItemComponent implements OnInit {

	@Input() item: StatsListItem;

	constructor(private translate: TranslateService) { }

	ngOnInit() {

	}

}
