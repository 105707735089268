import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PaginatorPtbr extends MatPaginatorIntl {

	constructor(private translate: TranslateService) {
		super();

		this.translate.get('paginator.itemsPerPage').subscribe(s => this.itemsPerPageLabel = s);
		this.translate.get('paginator.nextPage').subscribe(s => this.nextPageLabel = s);
		this.translate.get('paginator.previousPage').subscribe(s => this.previousPageLabel = s);
		this.translate.get('paginator.firstPage').subscribe(s => this.firstPageLabel = s);
		this.translate.get('paginator.lastPage').subscribe(s => this.lastPageLabel = s);
	}


	getRangeLabel = (page: number, pageSize: number, length: number) => {
		if (length === 0 || pageSize === 0) {
			return this.translate.instant('paginator.emptyRange', { length });
		}
		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		return this.translate.instant('paginator.range', { start: startIndex + 1, end: endIndex, length });
	};
}
