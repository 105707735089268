import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { MatInputModule } from '@angular/material/input';
import { ErrorStateMatcher as MaterialErrorStateMatcher } from '@angular/material/core';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideFilterModule } from './components/side-filter/side-filter.module';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ErrorStateMatcher } from './config/error-state-matcher';
import { FileUploadModule } from 'ng2-file-upload';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DateFormatAdapter, PICK_FORMATS } from './config/date-format-adapter';
import { PaginatorPtbr } from './config/paginator';
import { TextMaskModule } from 'angular2-text-mask';
import { ButtonsModule } from './components/buttons/buttons.module';
import { SharedFormsModule } from './forms/shared-forms.module';
import { MatButtonModule } from '@angular/material/button';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { translateServerLoaderFactory } from '@app-utils/translate-server-loader';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
	align: 'left',
	allowNegative: true,
	decimal: ',',
	precision: 2,
	prefix: 'R$ ',
	suffix: '',
	thousands: '.'
};

const imports = [
	CommonModule,
	PerfectScrollbarModule,
	ToastrModule.forRoot(),
	NgbModule,
	SharedComponentsModule,
	SharedDirectivesModule,
	SharedPipesModule,
	RouterModule,
	TranslateModule.forChild({
		loader: {
			provide: TranslateLoader,
			useFactory: (translateServerLoaderFactory),
			deps: [HttpClient]
		}
	})
];

const exportsComponents = [
	SharedFormsModule,
	CurrencyMaskModule,
	FileUploadModule,
	FormsModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatFormFieldModule,
	MatPaginatorModule,
	MatIconModule,
	MatInputModule,
	MatRadioModule,
	DragDropModule,
	MatSelectModule,
	MatSlideToggleModule,
	MatTabsModule,
	MatTooltipModule,
	MatButtonModule,
	MatChipsModule,
	MatAutocompleteModule,
	NgxMatSelectSearchModule,
	NgxPaginationModule,
	NgbModule,
	ReactiveFormsModule,
	SharedComponentsModule,
	SharedDirectivesModule,
	SharedPipesModule,
	TextMaskModule,
	MatDialogModule,
	ButtonsModule,
	TranslateModule,
	CdkAccordionModule,
	MatExpansionModule,
	MatSidenavModule,
	SideFilterModule
];

const providers = [
	{ provide: MaterialErrorStateMatcher, useValue: new ErrorStateMatcher() },
	{ provide: DateAdapter, useClass: DateFormatAdapter },
	{ provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS },
	{ provide: MatPaginatorIntl, useClass: PaginatorPtbr },
	{ provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
	{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
];

@NgModule({
	imports,
	exports: exportsComponents,
	providers
})
export class SharedModule { }
