import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PageControl } from '../model/page-control';
import { environment } from '@env/environment';
import { Observable, of, timer } from 'rxjs';
import { ProdutoPageableResponse, Product, ProductStatus, ProductInfo, FeatureHelper, ProductClients, DomainEntities, ProductClient } from '../model/product';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Utils } from '@app-shared/utils';
import { ToastrService } from 'ngx-toastr';
import { DefaultStatus } from '@app-model/login';
import { InterceptorSkipHeader } from './auth-interceptor.service';
import { PromotedContentType } from '@app-model/promotedContent';
import { City } from '@app-model/application';

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	private productInfo: ProductInfo;
	private loadingProductInfo = false;
	private domainEntities: DomainEntities;
	private loadingDomainEntities: boolean;

	constructor(private http: HttpClient, private toastr: ToastrService) { }

	getProductInfo(): Observable<ProductInfo> {
		if (this.loadingProductInfo && !this.productInfo) {
			//there is another process already loading... wait for it to resolve
			return timer(100).pipe(
				switchMap(() => this.getProductInfo())
			);
		}

		if (this.productInfo) {
			return of(this.productInfo);
		} else {
			this.loadingProductInfo = true;
			return this.http.get<ProductInfo>(`${environment.api}/open/productInfo`, {
				headers: new HttpHeaders()
					.set(InterceptorSkipHeader, '')
			})
				.pipe(
					tap(p => {
						this.productInfo = p;
						this.loadingProductInfo = false;
						return of(this.productInfo);
					})
				);
		}
	}


	getDomainEntities(): Observable<DomainEntities> {
		if (this.loadingDomainEntities && !this.domainEntities) {
			//there is another process already loading... wait for it to resolve
			return timer(50).pipe(
				switchMap(() => this.getDomainEntities())
			);
		}

		if (this.domainEntities) {
			return of(this.domainEntities);
		} else {
			this.loadingDomainEntities = true;
			return this.http.get<DomainEntities>(`${environment.api}/open/domainEntities`, {
				headers: new HttpHeaders()
					.set(InterceptorSkipHeader, '')
			})
				.pipe(
					map(d => {
						let dTemp = Object.assign(new DomainEntities(), d);
						dTemp.initDefiningEntity();
						this.domainEntities = dTemp;
						this.loadingDomainEntities = false;
						return this.domainEntities;
					})
				);
		}
	}


	getFeatures(): Observable<FeatureHelper[]> {
		return this.http.get<FeatureHelper[]>(`${environment.api}/product/features`);
	}

	getAvailablePromotedContents(entityType: string, entityId: string): Observable<PromotedContentType[]> {
		return this.http.get<PromotedContentType[]>(`${environment.api}/product/promotedContentTypes/${entityType}/${entityId}`);
	}

	get(id: string) {
		return this.http.get(`${environment.api}/product/${id}`).pipe(map((response: Product) => response));
	}

	paginate(pageControl: PageControl): Observable<ProdutoPageableResponse> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField
		};

		if (pageControl.param && pageControl.param !== '' && pageControl.value && pageControl.value !== '') {
			body.param = pageControl.param;
			body.value = pageControl.value;
		}
		return this.http
			.post(`${environment.api}/product/listPageable`, body)
			.pipe(map((response: ProdutoPageableResponse) => response));
	}

	downloadCSV() {
		this.http
			.get(`${environment.api}/product/generateCsv`, {
				headers: new HttpHeaders({
					Accept: 'application/csv'
				}),
				responseType: 'blob' as 'json'
			})
			.subscribe(Utils.downloadCSV, _ => {
				this.toastr.error('', 'Falha ao realizar o download do csv');
			});
	}

	getStatusProduct(): Observable<ProductStatus[]> {
		return this.http.get(`${environment.api}/product/getAllStatus`).pipe(map((response: ProductStatus[]) => response));
	}

	saveOrUpdate(product: Product): Observable<DefaultStatus> {
		return this.http
			.post(`${environment.api}/product/upsertProduct`, product)
			.pipe(map((response: DefaultStatus) => response));
	}

	listSellers(): Observable<any[]> {
		return this.http.post(`${environment.api}/seller/search`, {})
			.pipe(map((response: any) => response.data));
	}

	getClients(): Observable<ProductClients> {
		return this.http.get<ProductClients>(`${environment.api}/open/clients`);
	}


	getClient(permalink?: string): Observable<ProductClient> {
		return this.http.get<ProductClient>(`${environment.api}/open/client/${permalink}`);
	}

	getGroup(permalink?: string, clientPermalink?: string) {
		return this.http.get<ProductClient>(`${environment.api}/open/group${clientPermalink ? '/' + clientPermalink : ''}/${permalink}`);
	}

	getCityOptions(query: string): Observable<City[]> {
		if (!query || query.length < 3) return of([]);

		return this.http.get<City[]>(`${environment.api}/product/cityOptions/${query}`);
	}

}
