import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MaskService {
	private date = '00/00/0000';
	private time = '00:00:00';
	private datatime = '00/00/0000 00:00:00';
	private cep = '00000-000';
	private phone = '(00) 0000-0000';
	private cellPhone = '(00) 00000-0000';
	private cpf = '000.000.000-00';
	private cnpj = '00.000.000/0000-00';
	private percent = '##0,00%';
	private datebank = '00/0000';
	private cvv = '0000';
	private creditNumber = '0000 0000 0000 0000';

	constructor() {}

	getDate(): string {
		return this.date;
	}

	getTime(): string {
		return this.time;
	}

	getDateTime(): string {
		return this.datatime;
	}

	getCep(): string {
		return this.cep;
	}

	getPhone(): string {
		return this.phone;
	}

	getCellPhone(): string {
		return this.cellPhone;
	}

	getCpf(): string {
		return this.cpf;
	}

	getCnpj(): string {
		return this.cnpj;
	}

	getPercent(): string {
		return this.percent;
	}

	getDatebank(): string {
		return this.datebank;
	}

	getCvv() {
		return this.cvv;
	}

	getCreditNumber() {
		return this.creditNumber;
	}
}
