import { Component, OnInit, Input } from '@angular/core';
import { ProductInfo } from '@app-model/product';
import { ProductService } from '@app-services/product.service';

@Component({
	selector: 'app-qr-code',
	templateUrl: './qr-code.component.html',
	styleUrls: ['./qr-code.component.scss']
})
export class QRCodeComponent implements OnInit {
	@Input() url: string;
	@Input() width?: number;

	constructor(private productService: ProductService) { }

	ngOnInit() {
		if (!this.width) {
			this.width = 256;
		}

		this.productService.getProductInfo().subscribe(p => {
			this.url = QRCodeComponent.getUtmUrl(this.url, p);
		})

	}

	static getUtmUrl(url: string, product: ProductInfo): string {
		if (!url) {
			return url;
		}
		const utmParams = `utm_source=${product.name}&utm_medium=QRCode`;
		let paramsSeparator;
		if (url.indexOf('?') >= 0) {
			paramsSeparator = '&';
		} else {
			paramsSeparator = '?';
		}

		url += paramsSeparator + utmParams;
		return url;
	}
}
