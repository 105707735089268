import { Component, Inject, OnInit } from '@angular/core';
import { SessionService } from './store/session/session.service';
import { filter } from 'rxjs/operators';
import { ProductService } from '@app-services/product.service';
import { DOCUMENT } from '@angular/common';
import { ProductInfo } from '@app-model/product';

@Component({
	selector: 'app-root',
	template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

	productInfo: ProductInfo;

	constructor(private sessionService: SessionService,
		private productService: ProductService,
		@Inject(DOCUMENT) private document: Document) { }

	ngOnInit(): void {
		this.sessionService
			.isAuthenticated()
			.pipe(filter(isAuthenticated => isAuthenticated))
			.subscribe(() => {
				this.sessionService.getUser().subscribe();
			});
		this.productService.getProductInfo()
			.subscribe(p => {
				this.productInfo = p;
				this.initGtmScript();
				this.updateLangTag();
			});
	}


	private updateLangTag(): void {
		if (this.productInfo?.language) {
			const lang = this.productInfo.language.split('-')[0];
			const htmlTag = this.document.getElementsByTagName('html')[0];
			htmlTag.setAttribute("lang", lang);
		}
	}


	initGtmScript(): void {
		if (this.productInfo?.gtmCode) {
			let node = this.document.createElement('script'); // creates the script tag
			// node.type = 'text/javascript'; // set the script type
			node.async = true; // makes script run asynchronously
			node.innerText = `
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
				originalLocation: document.location.protocol + '//' +
					document.location.hostname +
					document.location.pathname +
					document.location.search
				});

			(function (w, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({
					'gtm.start': new Date().getTime(),
					event: 'gtm.js'
				});
				var f = d.getElementsByTagName(s)[0],
					j = d.createElement(s),
					dl = l != 'dataLayer' ? '&l=' + l : '';
				j.async = true;
				j.src =
					'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
				f.parentNode.insertBefore(j, f);
			})(window, document, 'script', 'dataLayer', '${this.productInfo.gtmCode}');`;

			// append to head of document
			const head = this.document.getElementsByTagName('head')[0];
			head.appendChild(node);

		}
	}
}
