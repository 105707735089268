import {
	Directive,
	Input,
	AfterViewInit,
	ComponentFactoryResolver,
	ViewContainerRef,
	Optional,
	Host,
	SkipSelf,
	ComponentRef,
	OnInit
} from '@angular/core';
import { FormControl, ControlContainer } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { environment } from '@env/environment';
import { MatInputValidateMessages } from './mat-input-validate-messages.component';
import { TranslateService } from '@ngx-translate/core';


export function controlPath(name: string, parent: ControlContainer): string[] {
	// tslint:disable-next-line:no-non-null-assertion
	return [...parent.path!, name];
}

@Directive({
	// tslint:disable-next-line: directive-selector
	selector: '[matInput]',
	// tslint:disable-next-line: no-host-metadata-property
	host: {
		'(blur)': 'onBlur($event)'
	}
})
export class MatInputValidateDirective implements AfterViewInit {
	@Input()
	formControlName: string;

	@Input() noValidate: boolean;

	@Input()
	formControl: string;

	cacheRef: ComponentRef<MatInputValidateMessages>;

	constructor(
		@Optional()
		@Host()
		@SkipSelf()
		private parent: ControlContainer,
		private vcr: ViewContainerRef,
		private resolver: ComponentFactoryResolver,
		private formField: MatFormField,
		private translate: TranslateService
	) { }


	ngAfterViewInit() {
		if (this.control && !this.noValidate) {
			this.control.statusChanges.subscribe(status => {
				if (status === 'INVALID') {
					this.displayErrors();
					return;
				} else if (this.cacheRef) {
					this.cacheRef.instance.messages = [];
				}
			});
		}
	}

	get path() {
		return controlPath(this.formControlName, this.parent);
	}

	onBlur($event) {
		this.displayErrors();
	}

	private get control(): FormControl {
		return this.formDirective && this.formDirective.getControl(this);
	}

	private get formDirective(): any {
		return this.parent ? this.parent.formDirective : null;
	}

	private get messages(): string[] {
		const { valid, dirty, touched, errors } = this.control;

		if (valid || (!dirty && !touched) || !errors) {
			return null;
		}

		const messages = [];
		Object.keys(errors).forEach(key => {
			this.translate.get('errors.' + key, errors).subscribe(ERROR => {
				if (ERROR) {
					messages.push(ERROR);
				} else if (!environment.production) {
					console.error(`Erro ${key} não mapeado na lista de erros`);
				}
			})
		});
		return messages;
	}

	private get ref() {
		if (!this.cacheRef) {
			const factory = this.resolver.resolveComponentFactory(MatInputValidateMessages);
			this.cacheRef = this.vcr.createComponent(factory);
		}
		return this.cacheRef;
	}

	private displayErrors() {
		if (this.control && this.formField && !this.noValidate) {
			this.ref.instance.messages = this.messages;
		}
	}
}
