import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
import { SidebarService } from '@app-services/sidebar.service';
import { ProductService } from '@app-services/product.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { FilterService } from '@app-services/filter.service';
import { MetaTagService } from '@app-services/meta-tag.service';
@Component({
	selector: 'app-layout-restrict-area',
	templateUrl: './layout-restrict-area.component.html',
	styleUrls: ['./layout-restrict-area.component.scss']
})
export class LayoutRestrictAreaComponent implements OnInit {
	moduleLoading: boolean;
	@ViewChild(PerfectScrollbarDirective, { static: true })
	perfectScrollbar: PerfectScrollbarDirective;

	@ViewChild('drawer')
	public drawer: ElementRef;
	public filterStatus$: Observable<boolean>;

	constructor(
		public readonly sidebarService: SidebarService,
		private readonly router: Router,
		private readonly productService: ProductService,
		private readonly translate: TranslateService,
		private readonly pageTitle: Title,
		private readonly filterService: FilterService,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly metaTagService: MetaTagService
	) {
		this.filterStatus$ = this.filterService.handleFilterStatus();
	}

	ngOnInit() {
		this.productService.getProductInfo().subscribe(p => {
			if (p.language) {
				this.translate.use(p.language);
			}
			this.metaTagService.updateFavicon(this.document, p.faviconUrl);
			this.pageTitle.setTitle(p.name);
		});

		this.router.events.subscribe(event => {
			if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
				this.moduleLoading = true;
			}
			if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
				this.moduleLoading = false;
			}
		});
	}

	public handleFilterStatus(status: boolean): void {
		if (!status) {
			this.filterService.closeFilter();
		}
	}
}
