import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SessionStore, UserInfo } from './session.store';
import { environment } from '@env/environment';
import { tap, catchError, map, take, mergeMap, shareReplay } from 'rxjs/operators';
import { throwError, Observable, of, from } from 'rxjs';
import { applyTransaction, resetStores } from '@datorama/akita';
import { Router } from '@angular/router';
import {
	LoginStatus,
	PasswordRegistrationResponse,
	PasswordRegistrationRequest,
	DefaultStatus,
	InitialSignUpRequest,
	InitialSignUpResponse
} from '@app-model/login';
import { ToastrService } from 'ngx-toastr';
import { AmplifyService } from '@app-services/amplify.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '@app-services/product.service';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	constructor(
		private sessionStore: SessionStore,
		private http: HttpClient,
		private amplifyService: AmplifyService,
		private router: Router,
		private toastr: ToastrService,
		private translate: TranslateService,
		private productService: ProductService
	) { }

	setLoading(status: boolean) {
		this.sessionStore.setLoading(status);
	}

	checkEmailStatus(email: string): Observable<LoginStatus> {
		this.setLoading(true);
		return this.http.get(`${environment.api}/login/status/${email}`).pipe(
			map((response: LoginStatus) => response),
			tap(() => this.sessionStore.setLoading(false)),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	createAccount(req: InitialSignUpRequest): Observable<InitialSignUpResponse> {
		return this.http.post<InitialSignUpResponse>(`${environment.api}/login/createAccount`, req);
	}

	resetPassword(userId: string, verifCode: string, password: string): Observable<PasswordRegistrationResponse> {
		this.setLoading(true);
		const request: PasswordRegistrationRequest = {
			userId,
			verifCode,
			password
		};
		return this.http.post(`${environment.api}/login/resetPassword`, request).pipe(
			map((response: PasswordRegistrationResponse) => response),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	verifyUser(userId: string, verifCode: string): Observable<DefaultStatus> {
		return this.http
			.get(`${environment.api}/login/verifyUser/${userId}/${verifCode}`)
			.pipe(map((response: DefaultStatus) => response));
	}

	forgotPassword(email: string) {
		this.setLoading(true);
		return this.http.get(`${environment.api}/login/resetPassword/${email}`).pipe(
			take(1),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	isAuthenticated(): Observable<boolean> {
		return from(this.amplifyService.isAuthenticated()).pipe(
			tap(isAuthenticated => {
				this.sessionStore.update({ isAuthenticated });
			})
		);
	}

	updateIsAuthenticated(isAuthenticated: boolean) {
		applyTransaction(() => {
			this.sessionStore.setLoading(false);
			this.sessionStore.update({ isAuthenticated });
		});
	}

	logout() {
		this.amplifyService.logout().subscribe(() => {
			this.sessionStore.clearSession();
			this.sessionStore.reset();
			resetStores();
			this.router.navigate(['/login']);
		});

	}

	registerUser(userId: string, verifCode: string, values): Observable<PasswordRegistrationResponse> {
		return this.http.post(`${environment.api}/login/registerNewUser/${userId}/${verifCode}`, values).pipe(
			take(1),
			map((response: PasswordRegistrationResponse) => response),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	getUser(): Observable<UserInfo> {
		return this.sessionStore
			._select(state => state.userInfo)
			.pipe(
				mergeMap(userInfo => {
					if (userInfo) {
						return of(userInfo);
					}
					return this.getUserFromBack();
				})
			);
	}

	getUserFromBack(): Observable<UserInfo> {
		return this.http.get(`${environment.api}/user/info`).pipe(
			map((userInfo: UserInfo) => Object.assign(new UserInfo(), userInfo)),
			tap(userInfo => this.sessionStore.update({ userInfo })),
			shareReplay()
		);
	}


	login(username: string, password: string, returnUrl?: string) {
		this.amplifyService.login(username, password).subscribe(
			response => {
				this.updateIsAuthenticated(true);
				this.getUser().subscribe(u => {
					this.productService.getProductInfo().subscribe(p => {
						if (p.plansEnabled) {
							if (u.missingPlan) {
								this.router.navigateByUrl('/admin/billing/plan');
								return;
							} else if (u.missingBillingInfo) {
								this.router.navigateByUrl('/admin/billing/billingInfo');
								return;
							}
						}
						this.router.navigateByUrl(returnUrl ? returnUrl : '/admin/dashboard');
					});
				});
			},
			err => {
				this.setLoading(false);
				const { code } = err;
				let message: string;
				if (code === 'NotAuthorizedException') {
					this.translate.get('login.incorrectUserPassword').subscribe(s => message = s);
				} else {
					this.translate.get('login.loginError').subscribe(s => message = s);
				}

				this.toastr.error('', message, {
					closeButton: true,
					progressBar: true,
					positionClass: 'toast-bottom-center',
					extendedTimeOut: 5000,
					timeOut: 10000
				});
			}
		);
	}
}
