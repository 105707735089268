export const environment = {
	appName: 'Keyou',
	home: '/login',
	production: false,
	api: 'https://api-keyou-dev.actionlabs.com.br/api/1.0',
	url: 'https://keyou-dev.actionlabs.com.br',
	defaultLocale: 'en-US',

	passwordRules: {
		minlength: 6,
		minLowercaseCharacterRule: 1,
		minUppercaseCharacterRule: 1,
		minDigitCharacterRule: 1,
		minSpecialCharacterRule: 1
	},

	title: 'Keyou',
	description: ``,
	image: '',
	keywords: '',
	stripeKey: 'pk_test_51OAxlRLgpjGj9huLUuNyJwZxVJGrbgRtEogs3z4B6p5QMtuA3YxINcYvHBKfd2BtWtEr8HZ1wF1sNpv5j4E3UenH00lz0QHPMQ'
};
