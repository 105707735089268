<div id="all" cdkDropList [cdkDropListData]="allButtons" class="buttons-list" (cdkDropListDropped)="drop($event)" [cdkDropListEnterPredicate]="noReturnPredicate">
  <div class="box-buttons-options" *ngFor="let button of allButtons; let i = index" [cdkDragData]="button" cdkDrag>
    <div class="custom-placeholder" *cdkDragPlaceholder></div>
    <i class="{{ button.icon }} icon-left"></i>
    <form class="w-100">
      <mat-form-field>
        <input matInput [placeholder]="button.label | translate"
          (input)="onLinkInputChange(button, $event.target.value);"
          (blur)="blurEvent.emit();"
          [value]="button.tempUrl"
        />
      </mat-form-field>
    </form>
    <i class="fas fa-grip-vertical icon-right"></i>
  </div>
</div>
