import { Injectable } from '@angular/core';
import { FileUploaderOptions, FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';

import { Observable, Subscriber } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AmplifyService } from './amplify.service';


interface FileUploaderActions {
	onBeforeUploadItem?(item: FileItem): any;
	onCompleteItem(item: FileItem, response: any, status: number, headers: ParsedResponseHeaders): any;
	onCancelItem?(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any;
}

@Injectable({
	providedIn: 'root'
})
export class FileUploaderService {
	defaultOptions = {
		url: `${environment.api}/file/saveFile`,
		autoUpload: true,
		isHTML5: true,
		headers: []
	};

	imgMarketplaceOptions = {
		url: environment.api,
		autoUpload: true,
		isHTML5: true,
		headers: [],
		maxFileSize: 4 * 1024 * 1024
	};

	constructor(private amplifyService: AmplifyService) {}

	/**
	 * Retorna uma nova instancia de FileUploader
	 */
	getInstance(actions: FileUploaderActions, options?: FileUploaderOptions): Observable<FileUploader> {
		// tslint:disable-next-line: deprecation
		return Observable.create(async (observer: Subscriber<FileUploader>) => {
			const jwt = await this.amplifyService.getJwt().toPromise();
			if (options) {
				options = { ...this.defaultOptions, ...options };
			} else {
				options = this.defaultOptions;
			}

			options.headers = [
				{
					name: 'Authorization',
					value: `Bearer ${jwt}`
				}
			];

			const fileUploader = new FileUploader(options);

			if (actions === null) {
				observer.next(fileUploader);
				observer.complete();
				return;
			}

			if (actions.onCompleteItem) {
				fileUploader.onCompleteItem = (
					item: FileItem,
					response: string,
					status: number,
					headers: ParsedResponseHeaders
				) => {
					try {
						actions.onCompleteItem(item, JSON.parse(response), status, headers);
					} catch (err) {
						actions.onCompleteItem(item, response, status, headers);
					}
				};
			}

			if (actions.onCancelItem) {
				fileUploader.onCancelItem = actions.onCancelItem;
			}

			observer.next(fileUploader);
			observer.complete();
		}).pipe(shareReplay(1));
	}

	getInstanceImgMarketplace(actions: FileUploaderActions, options?: FileUploaderOptions): Observable<FileUploader> {
		options.url = this.imgMarketplaceOptions.url + options.url;
		return Observable.create(async (observer: Subscriber<FileUploader>) => {
			const jwt = await this.amplifyService.getJwt().toPromise();
			if (options) {
				options = { ...this.imgMarketplaceOptions, ...options };
			} else {
				options = this.imgMarketplaceOptions;
			}

			options.headers = [
				{
					name: 'Authorization',
					value: `Bearer ${jwt}`
				}
			];

			const fileUploader = new FileUploader(options);

			if (actions === null) {
				observer.next(fileUploader);
				observer.complete();
				return;
			}

			if(actions.onBeforeUploadItem) {
				fileUploader.onBeforeUploadItem = actions.onBeforeUploadItem;
			}

			if (actions.onCompleteItem) {
				fileUploader.onCompleteItem = (
					item: FileItem,
					response: string,
					status: number,
					headers: ParsedResponseHeaders
				) => {
					try {
						actions.onCompleteItem(item, JSON.parse(response), status, headers);
					} catch (err) {
						actions.onCompleteItem(item, response, status, headers);
					}
				};
			}

			if (actions.onCancelItem) {
				fileUploader.onCancelItem = actions.onCancelItem;
			}

			observer.next(fileUploader);
			observer.complete();
		}).pipe(shareReplay(1));
	}
}
