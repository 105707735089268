<div class="late-payment" *ngIf="userInfo?.pendingPayment" [ngClass]="{'suspended': userInfo?.suspendedPayment}">
  <i class="fas fa-exclamation-circle"></i>
  <span translate *ngIf="!userInfo?.suspendedPayment" [translateParams]="{'validUntil': userInfo?.clientSuspensionDate | date: 'shortDate'}">billing.pendingPayment</span>
  <span translate *ngIf="userInfo?.suspendedPayment">billing.suspendedPayment</span>
  <a [routerLink]="['/admin/billing']" translate class="billing-warning">billing.warningLink</a>
</div>

<header id="main-header">
	<div class="menu-toggle" (click)="toggelSidebar()">
		<div></div>
		<div></div>
		<div></div>
	</div>
	<div class="logo">
		<a [routerLink]="['/']">
			<img [src]="productInfo?.secondaryLogoUrl" [alt]="productInfo?.name" height="24px" />
		</a>
	</div>

	<div class="ml-md-auto"></div>

	<div class="header-part-right" *ngIf="isAuthenticated$ | async">

		<div ngbDropdown [placement]="'bottom-right'" class="user col">
			<span id="userDropdown" ngbDropdownToggle alt="">
				<i class="fal fa-user-circle header-icon" role="button"></i>
				<span class="name-user">{{'header.hello' | translate}} {{ userInfo?.firstName }}</span>
				<i class="fas fa-angle-down ml-2"></i>
			</span>
			<div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
				<a [routerLink]="['/admin/billing']" class="dropdown-item" *ngIf="userInfo?.hasRole('CLIENT') && productInfo.plansEnabled">
					<i class="fas fa-angle-right"></i>
					{{'header.billing' | translate}}
				</a>
				<a [routerLink]="['/logout']" class="dropdown-item">
					<i class="fas fa-angle-right"></i>
					{{'header.logout' | translate}}
				</a>
			</div>
		</div>
	</div>
</header>
