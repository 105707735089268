import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-legend',
  templateUrl: './list-legend.component.html',
  styleUrls: ['./list-legend.component.scss']
})
export class ListLegendComponent implements OnInit {
  @Input() items = [];
  @Input() textInit = '';
  
  constructor() { }

  ngOnInit(): void {
  }

}
