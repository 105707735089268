<div class="ng2FileDrop class-ng2-file" ng2FileDrop [uploader]="uploader">
  <div class="row" *ngIf="type === 'typeUser'">
    <div class="col-12 drop-user">
      <i class="fal fa-upload"></i>
      <span class="span-layout">Arraste e solte a imagem aqui</span>
      <button type="button" class="btn btn-outline-primary button-upload">
        <span class="span-button-upload">SELECIONAR ARQUIVO</span>
      </button>
    </div>
  </div>
  <div class="row" *ngIf="type === 'typeEmphasis'">
    <div class="col-12">
      <div class="d-flex justify-content-center align-items-center mb-3 ">
        <i class="fal fa-upload pr-2"></i>
        <span class="span-layout">{{ labelText }}</span>
      </div>
      <button type="button" class="btn btn-outline-primary button-upload">
        <span class="span-button-upload">{{ labelButton }}</span>
      </button>
    </div>
  </div>
  <div class="row" *ngIf="type === 'typeGeneric'" style="height: 100%;">
    <div class="col-12 generic-type-row">
      <div class="mb-1"><i class="fal fa-upload pr-2"></i></div>
      <div class="mb-3"><span class="span-layout">{{ labelText }}</span></div>
      <button type="button" class="btn btn-outline-primary button-upload">
        <span class="span-button-upload">{{ labelButton }}</span>
      </button>
    </div>
  </div>
</div>
