import { NativeDateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DateFormatAdapter extends NativeDateAdapter {
	useUtcForDisplay = true;
	parse(value: any): Date | null {
		if (typeof value === 'string' && value.indexOf('/') > -1) {
			return moment(value, 'DD/MM/YYYY').toDate();
		}
		const timestamp = typeof value === 'number' ? value : Date.parse(value);
		return isNaN(timestamp) ? null : new Date(timestamp);
	}

	deserialize(value) {
		if (!value) {
			return null;
		}
		if (typeof value === 'string' && value.indexOf('/') > -1) {
			return moment(value, 'DD/MM/YYYY').toDate();
		}
		return moment(value).toDate();
	}
}

export const PICK_FORMATS = {
	parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
	display: {
		dateInput: 'input',
		monthYearLabel: { year: 'numeric', month: 'short' },
		dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'long' }
	}
};
