import { StoreConfig, Store, akitaConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';


export enum Role {
	ADMIN = 'ADMIN',
	PRODUCT_ADMIN = 'PRODUCT_ADMIN',
	CLIENT = 'CLIENT',
	GROUP = 'GROUP',
	CARDHOLDER = 'CARDHOLDER',
	USER = 'USER'
}

export class UserInfo {
	id: string;
	name: string;
	email: string;
	individualClient?: boolean;
	firstName: string;
	roles: string[];
	clientId: string;
	groupId: string;
	cardholderId: string;

	pendingPayment: boolean;
	suspendedPayment: boolean;
	clientSuspensionDate: Date;
	missingPlan?: boolean;
	missingBillingInfo?: boolean;


	hasRole(role: string): boolean {
		if (!this.roles || this.roles.length === 0) {
			return false;
		}
		for (const r of this.roles) {
			if (r === role) {
				return true;
			}
		}
		return false;
	}

	hasAnyRole(...roles: string[]) {
		return this.hasAnyRoleArr(roles);
	}


	hasAnyRoleArr(roles: string[]) {
		if (roles === undefined || roles.length === 0) {
			return true;
		}

		for (const role of roles) {
			if (this.hasRole(role)) {
				return true;
			}
		}
		return false;
	}
}

export interface SessionState {
	isAuthenticated: boolean;
	userInfo: UserInfo;
}

export function createInitialState(): SessionState {
	return {
		isAuthenticated: false,
		userInfo: null
	};
}

akitaConfig({
	resettable: true
})

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'sessionState' })
export class SessionStore extends Store<SessionState> {
	constructor() {
		super(createInitialState());
	}

	clearSession(): void {
		this._setState(state => createInitialState());
	}
}
