<div class="breadcrumb header-clients-360">
	<div class="row">
		<div class="col d-flex align-items-center">
			<a [routerLink]="['/admin']" translate>menu.home</a>
			<i class="material-icons">keyboard_arrow_right</i>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<h1 class="mt-2" translate>client.clients</h1>
		</div>
		<div class="col col-auto">
			<a [routerLink]="['/admin/personalization']" class="btn btn-outline-secondary d-flex align-items-center">
				<i class="fal fa-plus mr-2"></i>
				{{ 'client.addClient' | translate }}
			</a>
		</div>
	</div>

	<div class="row">
		<div class="col mt-2" translate [translateParams]="{ count: pageControl.count }">client.numClientsRegistered</div>
	</div>
</div>

<div class="row header-row-360">
	<div *ngIf="loading" class="loader-bubble loader-bubble-primary d-block"></div>
	<div class="col-md-12" *ngFor="let client of list">
		<div class="card mb-4">
			<div class="card-body">
				<div class="content-table-responsive">
					<div
						class="row hover align-items-center"
						[routerLink]="['/admin/personalization/', client.id]"
						style="cursor: pointer"
					>
						<div class="col">
							<div class="d-flex">
								<div class="logo-company" *ngIf="client.logoUrl">
									<div
										[ngStyle]="{ 'background-image': 'url(\'' + client.logoUrl + '\')' }"
										class="logo-company-image"
									></div>
								</div>
								<div class="logo-company d-flex justify-content-center align-items-center" *ngIf="!client.logoUrl">
									<span class="d-flex align-items-center flex-column text-uppercase">
										<i class="fal fa-image"></i>
										{{ 'noImage' | translate }}
									</span>
								</div>
								<div class="ml-3 content-name-type">
									<h4>{{ client.name }}</h4>
									<h5 class="text-uppercase">{{ 'client.type' + client.type | translate }}</h5>
								</div>
							</div>
							<div class="stats-list-360">
								<app-stats-list-item [item]="client"></app-stats-list-item>
							</div>
						</div>
						<div class="col-8 stats-list-1920">
							<app-stats-list-item [item]="client"></app-stats-list-item>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<btn-load-more [hasMore]="pageControl.hasMore" (loadMore)="loadMore()" [loading]="loading"></btn-load-more>
