import { ENTER } from '@angular/cdk/keycodes';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { LinkType, LinkUtils, Type, TypeButtons } from '@app-model/businessCard';
import { Client } from '@app-model/client';
import { LinkContents } from '@app-model/businessCard';
import { ProductService } from '@app-services/product.service';

@Component({
  selector: 'app-drag-drop-links',
  templateUrl: './drag-drop-links.component.html',
  styleUrls: ['./drag-drop-links.component.scss']
})
export class DragDropLinksComponent implements OnInit {

  allButtons: TypeButtons[] = [];

  @Input() initialFormValue: Client;
  @Input() typeForm: Type;
  @Output() updateFormArray: EventEmitter<void> = new EventEmitter<void>();

  @Output() inputChange: EventEmitter<{ button: any, value: string }> = new EventEmitter<{ button: any, value: string }>();
  @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateLinksOrderTwo: EventEmitter<LinkContents[]> = new EventEmitter<LinkContents[]>();

  constructor(private productService: ProductService) { }

  public ngOnInit() {

    this.productService.getProductInfo().subscribe(productInfo => {
      this.allButtons = LinkUtils.buildLinksArray(productInfo);
      if (this.initialFormValue && this.initialFormValue.cardholderInfo && this.initialFormValue.cardholderInfo.links && this.typeForm === Type.INDIVIDUAL) {
        const linksArrayControl = this.initialFormValue.cardholderInfo.links;

        this.allButtons = this._organizeAndFillTempUrl(
          this.allButtons,
          linksArrayControl
        );

      }

      if (this.initialFormValue && this.initialFormValue.footerLinks && this.typeForm === Type.ENTERPRISE) {
        const linksArrayControl = this.initialFormValue.footerLinks;

        this.allButtons = this._organizeAndFillTempUrl(
          this.allButtons,
          linksArrayControl
        );
      }

    });

  }

  public noReturnPredicate(): boolean {
    return false;
  }

  public drop(event: CdkDragDrop<any[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    this.updateFormArray.emit();
    this.saveLinksOrder();
  }

  private _organizeAndFillTempUrl(allButtons: TypeButtons[], allbuttonsLinks: LinkContents[]): TypeButtons[] {
    let updatedButtons: TypeButtons[] = allButtons
      .map((button, index) => ({
        ...button,
        tempUrl: '',
        selectedButton: index + 1,
      }));
    
    if (allbuttonsLinks && allbuttonsLinks.length) {
      updatedButtons = this._organizeAndFillTempUrlTemUrl(
        updatedButtons,
        allbuttonsLinks
      );

    } else {
      return updatedButtons;
    }

    return updatedButtons;
  }

  private _organizeAndFillTempUrlTemUrl(allButtons: TypeButtons[], allbuttons2: LinkContents[]): TypeButtons[] {
    const updatedButtons: TypeButtons[] = [];

    allbuttons2.forEach(button2 => {
      const foundButton = allButtons.find(button => button?.type === button2?.type);

      if (foundButton) {
        updatedButtons.push({
          ...foundButton,
          tempUrl: button2.url,
        });
      }
    });

    allButtons.forEach(button => {
      if (!allbuttons2.some(button2 => button2?.type === button?.type)) {
        updatedButtons.push(button);
      }
    });

    return updatedButtons;
  }

  public saveLinksOrder(): void {
    const orderedLinks = this.allButtons
      .filter(button => button.tempUrl !== '')
      .map(button => {
        return { type: button.type, url: button.tempUrl }
      });

    this.updateLinksOrderTwo.emit(orderedLinks);
  }

  public onLinkInputChange(button: TypeButtons, value: string): void {
    button.tempUrl = value;
    this.saveLinksOrder();
  }
}

