import { Injectable } from '@angular/core';
import { DashboardData, DashboardDataIds } from '@app-model/dashboard';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private postDataSubject = new BehaviorSubject<DashboardData>(null);
  postData$: Observable<DashboardData> = this.postDataSubject.asObservable();

  private _isOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private selectedIdsSubject = new BehaviorSubject<DashboardData>(null);

  selectedIds$: Observable<DashboardData> = this.selectedIdsSubject.asObservable();

  public updatePostData(data: DashboardData): void {
    this.postDataSubject.next(data);
  }

  public handleFilters(): Observable<DashboardData> {
    return this.postDataSubject.asObservable();
  }

  public openFilter(): void {
    this._isOpened.next(true);
  }

  public closeFilter(): void {
    this._isOpened.next(false);
  }

  public handleFilterStatus(): Observable<boolean> {
    return this._isOpened.asObservable();
  }

  public updateSelectedIds(selecteds: DashboardData): void {
    this.selectedIdsSubject.next(selecteds);
  }

}
