import { PromotedContentType } from "./promotedContent";

export interface Client {
  id: string;
  name: string;
}

export interface Group {
  client: Client;
  domain: string;
  id: string;
  name: string;
  permalink: string;
}

export interface Cardholder {
  client: Client;
  group: Group;
  id: string;
  imgUrl: string;
  name: string;
}

export interface DashboardData {
  clients: Client[];
  groups: Group[];
  cardholders: Cardholder[];
}

export interface DashboardDataListDrawer {
  key: string,
  value: Client[] | Group[] | Cardholder[]
}

export interface DashboardDataIds {
  clients: string[];
  groups: string[];
  cardholders: string[];
}

export interface DashboardStatistics {
  cardholders: {
    average: number;
    count: number;
    countPrevious: number;
    diff: number;
  };
  clicks: {
    count: number;
    countPrevious: number;
    diff: number;
  };
  mostViewedCardholder: {
    cardholder: {
      id: string;
      name: string;
    };
    canonicalURL: string;
    count: number;
  };
  pageviews: {
    count: number;
    countPrevious: number;
    diff: number;
  };
}

export interface DashboardTimeSeries {
  date: string,
  count: number
}

export enum LinkTypeStatsClick {
  SAVE_CONTACT = 'SAVE_CONTACT',
  WHATSAPP = 'WHATSAPP',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  YOUTUBE = 'YOUTUBE',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  TIKTOK = 'TIKTOK',
  YELP = 'YELP',
  MAPS = 'MAPS',
  WEBSITE = 'WEBSITE',
  MBC_ELEMENT = 'MBC_ELEMENT',
  LOGO = 'LOGO',
  SECONDARY_LOGO = 'SECONDARY_LOGO'
}

export interface ClickedLink {
  linkType: LinkTypeStatsClick;
  count: number;
}
export interface ClickedLinkPromotedContent {
  promotedContentType: PromotedContentType;
  count: number;
}

export interface PostData {
  cardholderIds: string[];
  clientIds: string[];
  end: string;
  groupIds: string[];
  ini: string;
  productId: string | null;
}

export interface PromotedContent {
  id: string;
  clientId: string;
  title: string;
  index: number;
  type: string;
  subType: string;
  entity: {
    name: string;
    id: string;
  };
  entityType: string;
  url: string
}
export interface PromotedContentData {
  count: number;
  promotedContent: PromotedContent
}

export interface PromotedContentInfo {
  count: number;
  url: string
}
export interface ContainerCardsHighlights {
  actualPage: number;
  data: PromotedContentData;
  numberOfPages: number;
  totalElements: number;
}

export interface BarChartDataItem {
  value: number;
  client: string;
  group?: string;
}
