import { layouts } from 'chart.js';
import { Client } from './client';
import { File } from './file';
import { Group } from './group';
import { CardholderLink } from './personalization';
import { LinkType } from './businessCard';

export interface Product {
	active: boolean;
	description: string;
	end: Date;
	id: string;
	init: Date;
	name: string;
	statusid: string;
	value: number;
	option: string[];
	files: File[];

	imgUrls?: string[];
}

export interface ProdutoPageableResponse {
	actualPage: number;
	data: Product[];
	numberOfPage: number;
	totalElements: number;
}

export class ProductStatus {
	id: string;
	description: string;
	statusCode: string;
}

export class ProductInfo {
	name: string;
	logoUrl: string;
	secondaryLogoUrl: string;
	logoDestinationUrl: string;
	faviconUrl: string;
	id: string;
	cognitoConfig?: CognitoConfig;
	language?: string;
	termsAndConditionsLink?: string;
	domain?: string;
	gtmCode?: string;
	plansEnabled?: boolean;
	selfRegistrationEnabled?: boolean;
	minCardsQty?: number;
	hideLinkTypes?: LinkType[];
}

export interface FeatureHelper {
	feature: string;
	providers: string[];
}

export class IntegrationConfig {
	id: string;
	feature: string;
	provider: string;
	token: string;
	lastUpdatedAt: Date;
	status: string;
	updateStartedAt: Date;
	errorData: string;
}

export class CognitoConfig {
	region: string;
	clientId: string;
	poolId: string;
}

export class ProductClients {
	enterprise: ProductEntity[];
	individual: ProductEntity[];
}

export class ProductClient {
	id: string;
	name: string;
	createdAt: Date;
	lastUpdatedAt: Date;
	aboutText: string;
	logoURL: string;
	heroImageURL: string;
	email: string;
	phoneNumber: string;
	groups: ProductEntity[];
	cardholders: ProductEntity[];
	linkTypes: string[];
	promotedContentTitles: string[];
}

export class ProductEntity {
	name: string;
	logoURL: string;
	canonicalURL: string;

	tagline: string;
}

export class DomainEntities {
	product: ProductInfo;
	client: Client;
	group: Group;

	definingEntity: DomainEntity;

	initDefiningEntity(): void {
		this.definingEntity = new DomainEntity();

		let layoutScheme = undefined;

		if (this.group) {
			this.definingEntity.name = this.group.name;
			this.definingEntity.permalink = this.group.permalink;
			this.definingEntity.canonicalURL = this.group.canonicalURL;
			this.definingEntity.footerLinks = this.group.footerLinks;

			layoutScheme = this.group.layoutScheme;
		} else if (this.client) {
			this.definingEntity.name = this.client.name;
			this.definingEntity.permalink = this.client.permalink;
			this.definingEntity.canonicalURL = this.client.canonicalURL;
			this.definingEntity.footerLinks = this.client.footerLinks;

			layoutScheme = this.client.layoutScheme;
		} else {
			this.definingEntity.name = this.product.name;
			this.definingEntity.canonicalURL = this.product.domain;

			layoutScheme = {
				logoImageUrl: this.product.secondaryLogoUrl,
				logoDestinationUrl: this.product.logoDestinationUrl
			}
		}


		if (layoutScheme) {
			this.definingEntity.logoURL = layoutScheme.logoImageUrl;
			this.definingEntity.logoDestinationURL = layoutScheme.logoDestinationUrl;
			this.definingEntity.secondaryLogoURL = layoutScheme.secondaryLogoImageUrl;
			this.definingEntity.secondaryLogoDestinationURL = layoutScheme.secondaryLogoDestinationUrl;
			this.definingEntity.buttonBgColor = layoutScheme.primaryButtonBgColor;
			this.definingEntity.buttonTextColor = layoutScheme.primaryButtonTextColor;
			this.definingEntity.secondaryColor = layoutScheme.secondaryColor;
			this.definingEntity.bgColor = layoutScheme.bgColor;

			if (layoutScheme.enableHeader) {
				this.definingEntity.headerBgColor = layoutScheme.headerColor;
			} else {
				this.definingEntity.headerBgColor = layoutScheme.bgColor;
			}
		}

	}
}

export class DomainEntity {
	name: string;
	logoURL: string;
	secondaryLogoURL: string;
	logoDestinationURL: string;
	secondaryLogoDestinationURL: string;
	headerBgColor: string;
	buttonTextColor: string;
	buttonBgColor: string;
	secondaryColor: string;
	bgColor: string;
	permalink: string;
	canonicalURL: string;
	footerLinks: CardholderLink[];
}

export class ProfilePage {

}