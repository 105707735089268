import { Component, OnInit, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
	selector: 'app-ng2-file-drop',
	templateUrl: './ng2-file-drop.component.html',
	styleUrls: ['./ng2-file-drop.component.scss']
})
export class Ng2FileDropComponent implements OnInit {
	@Input() uploader: FileUploader;
	@Input() multiple: boolean;
  @Input() type: string;
  @Input() labelText: string;
  @Input() labelButton: string;

	constructor() {}

	ngOnInit() {}
}
