import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';

const customPresets: string[] = [
  'rangeDate.TODAY',
  'rangeDate.YESTERDAY',
  'rangeDate.LAST_7_DAYS',
  'rangeDate.LAST_WEEK',
  'rangeDate.THIS_WEEK',
  'rangeDate.LAST_28_DAYS',
  'rangeDate.LAST_30_DAYS',
  'rangeDate.THIS_MONTH',
  'rangeDate.LAST_MONTH',
  'rangeDate.THIS_YEAR',
  'rangeDate.LAST_YEAR'
];

type CustomPreset = typeof customPresets[number];

@Component({
  selector: 'app-custom-range-panel',
  templateUrl: './custom-range-panel.component.html',
  styleUrls: ['./custom-range-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomRangePanelComponent<D> implements OnInit {

  readonly customPresets = customPresets;
  @HostBinding('class.touch-ui')
  readonly isTouchUi = this.picker.touchUi;

  selected: Date | null;

  @Input() buttonSelected: string = 'rangeDate.LAST_30_DAYS'

  constructor(
    private dateAdapter: DateAdapter<D>,
    private picker: MatDateRangePicker<D>
  ) {}

  ngOnInit(): void {
    this.buttonSelected = localStorage.getItem('buttonSelected');
  }

  selectRange(rangeName: CustomPreset): void {
    const [start, end] = this.calculateDateRange(rangeName);
    this.picker.select(start);
    this.picker.select(end);
    this.picker.close();
  }

  private calculateDateRange(rangeName: CustomPreset): [start: D, end: D] {
    const today = this.today;
    const yesterDay = this.dateAdapter.addCalendarDays(today, -1);
    const year = this.dateAdapter.getYear(today);
    this.buttonSelected = rangeName;
    localStorage.setItem('buttonSelected', rangeName)

    switch (rangeName) {
      case 'rangeDate.Today':
        return [today, today];
      case 'rangeDate.YESTERDAY':
        const start = this.dateAdapter.addCalendarDays(today, -1);
        return [start, start];
      case 'rangeDate.LAST_7_DAYS': {
        const start = this.dateAdapter.addCalendarDays(yesterDay, -6);
        return [start, yesterDay];
      }
      case 'rangeDate.LAST_28_DAYS': {
        const start = this.dateAdapter.addCalendarDays(yesterDay, -27);
        return [start, yesterDay];
      }
      case 'rangeDate.LAST_30_DAYS': {
        const start = this.dateAdapter.addCalendarDays(yesterDay, -29);
        return [start, yesterDay];
      }
      case 'rangeDate.LAST_WEEK': {
        const thisDayLastWeek = this.dateAdapter.addCalendarDays(yesterDay, -7);
        return this.calculateWeek(thisDayLastWeek);
      }
      case 'rangeDate.THIS_WEEK': {
        return this.calculateWeek(today);
      }
      case 'rangeDate.THIS_MONTH': {
        return this.calculateMonth(today);
      }
      case 'rangeDate.LAST_MONTH': {
        const thisDayLastMonth = this.dateAdapter.addCalendarMonths(today, -1);
        return this.calculateMonth(thisDayLastMonth);
      }
      case 'rangeDate.THIS_YEAR': {
        const start = this.dateAdapter.createDate(year, 0, 1);
        const end = this.dateAdapter.createDate(year, 11, 31);
        return [start, end];
      }
      case 'rangeDate.LAST_YEAR': {
        const start = this.dateAdapter.createDate(year - 1, 0, 1);
        const end = this.dateAdapter.createDate(year - 1, 11, 31);
        return [start, end];
      }
      default:
        return [today, today];
    }
  }

  private calculateMonth(forDay: D): [start: D, end: D] {
    const year = this.dateAdapter.getYear(forDay);
    const month = this.dateAdapter.getMonth(forDay);
    const start = this.dateAdapter.createDate(year, month, 1);
    const end = this.dateAdapter.addCalendarDays(
      start,
      this.dateAdapter.getNumDaysInMonth(forDay) - 1
    );
    return [start, end];
  }

  private calculateWeek(forDay: D): [start: D, end: D] {
    const deltaStart =
      this.dateAdapter.getFirstDayOfWeek() -
      this.dateAdapter.getDayOfWeek(forDay);
    const start = this.dateAdapter.addCalendarDays(forDay, deltaStart);
    const end = this.dateAdapter.addCalendarDays(start, 6);
    return [start, end];
  }

  private get today(): D {
    const today = this.dateAdapter.getValidDateOrNull(new Date());
    if (today === null) {
      throw new Error('date creation failed');
    }
    return today;
  }
}
