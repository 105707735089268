import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutSessionComponent } from './layout-session/layout-session.component';
import { RouterModule } from '@angular/router';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';
import { LayoutRestrictAreaComponent } from './layout-restrict-area/layout-restrict-area.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutRestrictAreaHeaderComponent } from './layout-restrict-area/header/layout-restrict-area-header.component';
import { LayoutRestrictAreaSidebarComponent } from './layout-restrict-area/sidebar/layout-restrict-area-sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '@app-shared/directives/shared-directives.module';
import { LayoutRestrictAreaFooterComponent } from './layout-restrict-area/footer/layout-restrict-area-footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideFilterModule } from '@app-shared/components/side-filter/side-filter.module';
import { translateServerLoaderFactory } from '@app-utils/translate-server-loader';

@NgModule({
	declarations: [
		LayoutSessionComponent,
		LayoutBlankComponent,
		LayoutRestrictAreaComponent,
		LayoutRestrictAreaHeaderComponent,
		LayoutRestrictAreaSidebarComponent,
		LayoutRestrictAreaFooterComponent
	],
	imports: [CommonModule, RouterModule, PerfectScrollbarModule, NgbModule, SharedPipesModule, SharedDirectivesModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: (translateServerLoaderFactory),
				deps: [HttpClient]
			}
		}),
		MatSidenavModule,
		SideFilterModule
	],
	exports: [LayoutSessionComponent, LayoutRestrictAreaComponent, LayoutBlankComponent]
})
export class LayoutsModule { }
