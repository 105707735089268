import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import slugify from 'slugify';

export class Utils {
	static isMobile() {
		return window && window.matchMedia('(max-width: 991px)').matches;
	}
	static ngbDateToDate(ngbDate: { month; day; year }) {
		if (!ngbDate) {
			return null;
		}
		return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
	}
	static dateToNgbDate(date: Date) {
		if (!date) {
			return null;
		}
		date = new Date(date);
		return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() };
	}
	static scrollToTop(selector: string) {
		if (document) {
			const element = document.querySelector(selector) as HTMLElement;
			element.scrollTop = 0;
		}
	}
	static genId() {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}

	static downloadCSV(blob) {
		if (blob) {
			const url = window.URL.createObjectURL(blob);
			window.open(url);
		}
	}

	static saveBlobAsFile(filename: string, blob: any): void {
		if (blob) {
			const a = document.createElement('a');
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = filename;
			a.click();
			document.body.removeChild(a);
			setTimeout(() => {
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	}

	static slug(text: string) {
		return slugify(text, {
			lower: true
		});
	}

	static _isFile(file: File | Blob | string | ArrayBuffer): boolean {
		return file instanceof File || file instanceof Blob;
	}

	static toBase64(file: File | string | ArrayBuffer): Promise<string | ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file as Blob);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

	static getColorByIndex(index: number): string {
		const customColors = [
			'#054659',
			'#F18700',
			'#2A77AD',
			'#F9B000',
			'#3BB93B',
			'#7460DD',
			'#0099C9',
			'#F0BE4B',
			'#61CE61',
			'#20829E',
			'#9487D3',
			'#FFAC41',
			'#50BEDF',
			'#B9DFB9',
			'#6952D9',
			'#0DA9D9',
			'#F29E2F',
			'#1CAE94',
			'#B47F03',
			'#3494D9',
			'#503FA6',
			'#73D973',
			'#D97B00',
			'#EBA70A',
			'#EBC70A'
		];

		if (index >= 0 && index < customColors.length) {
			return customColors[index];
		} else {
			return '#000000';
		}
	}

	static getLinkTypesAsString(translate: TranslateService, linkTypes: string[]): string {
		if (!linkTypes || linkTypes.length === 0) {
			return '';
		}

		const linkTypeLabels = linkTypes.map(t => translate.instant('contactType.' + t));

		return Utils.arrayToString(translate, linkTypeLabels);
	}

	static arrayToString(translate: TranslateService, arr: string[]): string {
		if (!arr || arr.length === 0) {
			return '';
		}

		if (arr.length === 1) {
			return arr[0];
		}
		return arr.slice(0, arr.length - 1).join(', ') + ' ' + translate.instant('and') + ' ' + arr[arr.length - 1];
	}

	static handleFileError(error: unknown, consoleMsg: string, translate: TranslateService, toastr: ToastrService): void {
		console.error(consoleMsg, error);
		translate.get('personalization.genericUploadError').subscribe(r => toastr.error('', r));
	}

	static handleImageUpload(
		file: File,
		maxFileSize: number,
		callback: (base64File: string | ArrayBuffer) => void,
		translate: TranslateService,
		toastr: ToastrService
	): void {
		const validMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp'];
		if (!validMimeTypes.includes(file.type)) {
			translate.get('personalization.invalidFileType').subscribe(r => toastr.warning('', r));
			return;
		}

		const MAX_FILE_SIZE_BYTES = maxFileSize * 1024;
		if (file.size <= MAX_FILE_SIZE_BYTES) {
			Utils.toBase64(file)
				.then(r => callback(r))
				.catch(error => {
					this.handleFileError(error, 'base64 encoding', translate, toastr);
				});
		} else {
			translate.get('personalization.maxFileSizeExceeded').subscribe(r => toastr.warning('', r));
		}
	}
}
