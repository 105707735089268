import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgThumb'
})
export class ImgThumbPipe implements PipeTransform {

  transform(value: string, type?: 'SMALL'): string {
		let imgUrl: string = value;

		if (imgUrl.length) {
			const dot = imgUrl.lastIndexOf('.');
			if (!type) {
				imgUrl = `${imgUrl.substring(0, dot)}${imgUrl.substring(dot)}`;
			} else {
				imgUrl = `${imgUrl.substring(0, dot)}${imgUrl.substring(dot)}`;
			}
		}

		return imgUrl;
	}

}
