import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Utils } from '@app-shared/utils';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss']
})
export class ChartLegendComponent {

  @Output() submit = new EventEmitter<{label: string, data: number}>();
  @Output() restoreFilter = new EventEmitter<null>();

  @Input() legends: string[];
  @Input() buildingPDF: boolean;
  @Input() datasets;

  @Input() clearFilter: boolean = false

  constructor(
  ) { }

  ngOnInit() {
  }

  public getColor(index: number): string {
    return Utils.getColorByIndex(index);
  }

  // public handleFilterChartLabels(ValueLabel: string, index: number): void {
  //   this.clearFilter = !this.clearFilter
  //   if(this.clearFilter) {
  //     this.submit.emit({label: ValueLabel, data: this.datasets[index]});
  //   }
  // }

  // public handleRestoreFilter(): void {
  //   this.clearFilter = !this.clearFilter
  //   this.restoreFilter.emit();
  // }
}
