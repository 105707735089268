import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'btn-load-more',
	templateUrl: './btn-load-more.component.html',
	styleUrls: ['./btn-load-more.component.scss']
})
export class BtnLoadMoreComponent implements OnInit {
	@Input() loading = false;
	@Input() hasMore = false;
	@Output() loadMore = new EventEmitter<void>();

	constructor() { }

	ngOnInit() { }

	loadMoreCallback(): void {
		this.loadMore.emit();
	}
}
